import '@toast-ui/editor/dist/toastui-editor.css';

import { Vue, Component, Prop } from "vue-property-decorator";
import { Editor } from "@toast-ui/vue-editor";

@Component({
  name: 'TextHtmlEditor',
  components: { 'vue-toast-editor': Editor }
})
export default class extends Vue {
  @Prop()
  value?: string;
  @Prop({ default: '200px' })
  height?: string;

  dynamicRef: string = null;
  created() {
    this.dynamicRef = `toastuiEditor${(Math.random() * 100000).toFixed(0)}`
  }

  editorOptions = {
    minHeight: '200px',
    language: 'it',
    useCommandShortcut: true,
    usageStatistics: true,
    hideModeSwitch: true,
    toolbarItems: [
      ['bold', 'italic', 'strike'],
      ['ul', 'ol'],
      ['indent', 'outdent'],
    ]
  }

  onChange() {
    this.$emit('input', (this.$refs[this.dynamicRef] as Editor).invoke('getHTML'));
  }
}