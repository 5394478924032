import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';

@Component({
  name: "TimelineStoricoStati"
})
export default class TimelineStoricoStati extends Vue {
  @Prop({ required: true })
  corso: corso.item;

  reverse: boolean = true;
  get stati() {
    return ['inCompilazione', 'proposto', 'approvato', 'rifiutato', 'pubblicato', 'inErogazione', 'sospeso', 'annullato', 'obsoleto', 'chiuso'];
  }

  get storicoStatiCorso(): corso.storicoStatoCorso[] {
    return this.corso.storicoStato;
  }
}
