import { ElForm } from 'element-ui/types/form';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { periodoFormativoService } from '../services/periodoFormativoService';

@Component({})
export default class SelectPeriodoFormativo extends Vue {
  @Prop({ required: true })
  periodoFormativo: periodoFormativo.item;

  @Prop({ required: true }) //, default: () => []
  periodiFormativi: periodoFormativo.item[];

  command: {
    periodoFormativoId: string,
  } = {
      periodoFormativoId: null,
    }

  async created() {
    this.command.periodoFormativoId = this.periodoFormativo?.id ?? null;
  }


  get computedPeriodoFormativo(): periodoFormativo.item {
    if (!this.command.periodoFormativoId) return null;
    var pf = this.periodiFormativi.find(f => f.id === this.command.periodoFormativoId);
    if (!pf) return null;
    return pf;
  }

  get formRules() {
    return {
      periodoFormativoId: [{ required: true, trigger: "change", message: `E' obbligatorio selezionare il periodo formativo` }],
    }
  }

  confirm() {
    (this.$refs.form as ElForm).validate(valid => {
      if (valid && this.computedPeriodoFormativo) {
        this.$emit("confirm", this.computedPeriodoFormativo);
        this.$emit("close");
      }
    })
  }
}
