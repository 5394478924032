import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import RightPanel from "@/components/RightPanel/index.vue";

import enteAutocomplete from '@/modules/enti/components/enteAutocomplete.vue';
import referenteAutocomplete from '@/modules/corsi/components/referenteAutocomplete/referenteAutocomplete.vue';
import pofAutocomplete from '@/modules/offertaFormativa/components/pofAutocomplete.vue';
import {store} from '@/store';
@Component({
  components: {
    RightPanel,
    enteAutocomplete,
    referenteAutocomplete,
    pofAutocomplete,
  }
})

export default class filterCorsi extends Vue {

  @Prop({ required: true })
  query: corso.queryParams;

  get stati(): string[] {
    return ['inCompilazione', 'proposto', 'rifiutato', 'pubblicato', 'inErogazione', 'sospeso', 'annullato', 'obsoleto', 'chiuso'];
  }

  erogazione: number = 0;

  get filterActive(): boolean {
    this.erogazione = 
      store.state.corsi.query.continuativo ? 3 :
      store.state.corsi.query.virtuale ? 2 :
      (store.state.corsi.query.virtuale === false 
        && store.state.corsi.query.continuativo === false ) ? 1 : 0;
    return (this.query &&
      (
        !!this.query.search ||
        !!this.query.stato ||
        !!this.query.pofId ||
        !!this.query.referenteId ||
        !!this.query.enteId ||
        (this.query.deontologico === true || this.query.deontologico === false) ||
        (this.query.gratuito === true || this.query.gratuito === false) ||
        this.erogazione > 0
      ));
  }

  @Watch("erogazione")
  erogazioneChange(n: number, o: number) {
    if (n !== undefined && n !== null && n !== o) {
      switch (n) {
        case 1: {
          this.query.continuativo = false;
          this.query.virtuale = false;
          break;
        }
        case 2: {
          this.query.continuativo = false;
          this.query.virtuale = true;
          break;
        }
        case 3: {
          this.query.continuativo = true;
          this.query.virtuale = null;
          break;
        }
        default:{
          this.query.continuativo = null;
          this.query.virtuale = null;
          break;
        }
      }
    }
  }

  handleFilter() {
    this.query.page = 1;
    (this.$refs.filterPanel as any).show = false;
    this.$emit('change', this.query);
  }

  handleClean() {
    (this.$refs.filterPanel as any).show = false;
    this.erogazione = 0;
    this.$emit('clean', {
      search: null,
      categoria: null,
      codiceFiscale: null,
      mail: null,
      pec: null,
      state: null,
      tessera: null,
      continuativo: null,
      virtuale: null,
      deontologico: null,
      gratuito: null,
      orderField: this.query.orderField,
      orderDescending: this.query.orderDescending,
      page: 1,
      take: 20
    } as corso.queryParams);
  }
}