import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { sessioneService } from '../../services/sessioneService';
import { moduliService } from '@/modules/moduli/service/moduliService';
import { corsiService } from '@/modules/corsi/services/corsiService';

import DraggableRelatori from '../draggble-relatori/draggable-relatori.vue'
import { relatoreSessioneService } from '../../services/relatoreSessioneService';
import { formatTime } from '@/filters';

@Component({
    name: "ManageSessioni",
    components: {
        DraggableRelatori
    }
})
export default class ManageSessioni extends Vue {
    @Prop()
    value?: sessione.item;

    @Prop({ required: true })
    corsoId: string;

    @Prop()
    corsoData: string | Date

    @Prop({ required: false, default: false })
    disabled: boolean;

    get dataDiRiferimentoCorso() {
        const data = this.corsoData || this.corso?.data;
        if (!data)
            return null;
        return new Date(formatTime(data, 'YYYY-MM-DD'))
    }

    get validationRules() {
        return {
            nome: [{ required: true, trigger: 'blur', message: `Il campo 'Nome' è obbligatorio` }],
            inizio: [{ required: true, trigger: 'change', message: `Il campo 'Data di inizio' è obbligatorio` }],
            durata: [{ required: true, trigger: 'blur', message: `Il campo 'Durata in minuti' è obbligatorio` }],
            moduli: [{ required: true, trigger: 'change', message: `E' necessario associare la sessione ad almeno un modulo del corso` }],
        }
    }

    corso: corso.item = null;
    moduli: modulo.item[] = [];
    manage: sessione.item = null;
    async created() {
        this.corso = await corsiService.Detail(this.corsoId);
        this.moduli = await moduliService.List(this.corsoId);

        if (!this.dataDiRiferimentoCorso) {
            this.$alert(`Prima di poter aggiungere o modificare una sessione, è necessario specificare una data per il corso ${this.corso.titolo}.`, 'Attenzione!', {
                confirmButtonText: 'Ok',
                type: "warning",
                dangerouslyUseHTMLString: true
            });
            this.$emit('close');
        }

        if (this.value) {
            this.manage = Object.assign({}, this.value);
        } else {
            this.manage = {
                corsoId: this.corsoId,
                nome: null,
                inizio: this.dataDiRiferimentoCorso,
                durata: 60,
                luogo: null,
                moduli: [],
            }
        }
    }

    get title() {
        if (!this.manage?.id)
            return `Aggiungi una nuova sessione al Corso`
        return `Modifica la sessione <strong>${this.value.nome}</strong>`
    }

    get dataInizioPickerOptions() {
        const _self = this;
        return {
            disabledDate(time: Date) {
                if (!_self.corso.data) return true;
                const dataCorso: Date = _self.dataDiRiferimentoCorso;
                return time.getTime() < dataCorso.getTime();
            }
        };
    }

    get tempoSuggerito() {
        if (!this.manage || this.manage.moduli.length <= 0) return 0;
        let minuti = 0;
        this.moduli.filter(f => this.manage.moduli.includes(f.id)).forEach(f => {
            minuti += (f.ore * 60) + f.minuti;
        })
        return minuti;
    }

    isIndeterminate: boolean = true;
    checkAll: boolean = false;
    handleCheckAllModuliChange(val) {
        this.manage.moduli = val ? this.moduli.map(m => m.id) : [];
        this.isIndeterminate = false;
    }

    @Watch("manage.moduli")
    handleCheckedModuliChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.moduli.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.moduli.length;
    }

    relatoriSessione: relatoreSessione.item[] = [];
    handleDraggableRelatoriChange(relatori: relatoreSessione.item[]) {
        this.relatoriSessione = relatori;
        if (this.relatoriCheckedValidation !== null)
            this.relatoriCheckedValidation = this.relatoriSessione.length > 0;
    }

    sessioneCheckedValidation?: boolean = null;
    relatoriCheckedValidation?: boolean = null;
    validateForms(): boolean {
        (this.$refs.form as ElForm).validate(valid => (this.sessioneCheckedValidation = valid));
        this.relatoriCheckedValidation = this.relatoriSessione.length > 0;
        return [this.sessioneCheckedValidation, this.relatoriCheckedValidation].reduce((a, b) => a && b, true);
    }

    saving: boolean = false;
    async save() {
        const valid = this.validateForms();
        if (valid) {
            this.saving = true;
            sessioneService.Set(this.corsoId, this.manage).then(async (sessione) => {
                let relatoriAssigned = await relatoreSessioneService.List(this.corsoId, { sessioneId: sessione.id });
                if (this.relatoriSessione && this.relatoriSessione.length > 0) {
                    for (const relatoreSessione of this.relatoriSessione) {
                        const idx = relatoriAssigned.findIndex(f => f.corsoId === relatoreSessione.corsoId
                            && f.relatoreId === relatoreSessione.relatoreId
                            && f.sessioneId === relatoreSessione.sessioneId);
                        if (idx >= 0) relatoriAssigned.splice(idx, 1);
                        else {
                            relatoreSessione.sessioneId = sessione.id;
                            relatoreSessioneService.Set(this.corsoId, relatoreSessione)
                        }
                    }
                }
                if (relatoriAssigned && relatoriAssigned.length > 0) {
                    for (const relatoreToBeReomve of relatoriAssigned) {
                        relatoreSessioneService.Remove(this.corsoId, relatoreToBeReomve.relatoreId, relatoreToBeReomve.sessioneId);
                    }
                }
                this.saving = false;
                this.$emit('change', sessione);
            }).catch(() => {
                this.saving = false;
                this.$message({
                    message: `Errore durante l'${this.manage.id ? 'aggiornamento' : 'inserimento'} della sessione ${this.manage.nome}`,
                    type: "error",
                });
            });
        }
    }
}