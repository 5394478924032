import { iscrittoService } from '@/modules/giornalisti/services/iscrittoService';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class IscrittoAutocomplete extends Vue {
  @Prop()
  value?: string;
  @Prop({ required: true })
  corsoId: string;

  @Prop({ default: false })
  clearable?: boolean;
  @Prop({ default: false })
  defaultFirstOption: boolean;
  @Prop({ default: `Ricerca l'iscritto` })
  placeholder?: string;
  @Prop({})
  size?: 'medium' | 'mini' | 'small';
  @Prop({})
  className?: string;
  @Prop({ default: false })
  disabled: boolean;
  @Prop()
  ammesso?: boolean;
  @Prop()
  frequentato?: boolean;
  @Prop()
  cancellato?: boolean;

  loading: boolean = false;
  options: { value: string; label: string }[] = [];

  public get selected(): string {
    return this.value;
  }
  public set selected(value: string) {
    this.$emit('input', value);
    this.$emit('change', value);
  }

  created() {
    this.initDefaultSelect();
  }

  @Watch('value')
  async valueChange(n, o) {
    if (n !== o) {
      await this.initDefaultSelect();
    }
  }

  async initDefaultSelect() {
    if (this.value) {
      const s = await iscrittoService.Detail(this.corsoId, this.value);
      if (!s) {
        this.selected = null;
      } else if (this.options.findIndex(f => f.value === s.anagraficaGiornalistaId) < 0) {
        this.options.push({ label: s.nomeCompleto, value: s.anagraficaGiornalistaId });
      }
    } else {
      if (this.selected) this.selected = null;
    }
  }

  async remoteMethod(query) {
    var take = 20;
    if (query !== '' && query.toLowerCase !== undefined) {
      take = 0; //all
    }

    this.loading = true;
    let iscritti = await iscrittoService.List(this.corsoId, { search: query, ammesso: this.ammesso, cancellato: this.cancellato, frequentato: this.frequentato, page: 1, take });
    if (iscritti && iscritti.length > 0) {
      if (take === 0) {
        iscritti = iscritti.filter(f => f.nomeCompleto.toLowerCase().indexOf(query.toLowerCase()) > -1).slice(0, 20);
      }
      this.options = iscritti.map(m => {
        return { label: m.nomeCompleto, value: m.anagraficaGiornalistaId };
      });
    } else {
      this.options = [];
    }
    this.loading = false;
  }
}
