import { render, staticRenderFns } from "./newRelatore.vue?vue&type=template&id=190de206&scoped=true"
import script from "./newRelatore.ts?vue&type=script&lang=ts&external"
export * from "./newRelatore.ts?vue&type=script&lang=ts&external"
import style0 from "./newRelatore.vue?vue&type=style&index=0&id=190de206&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "190de206",
  null
  
)

export default component.exports