import { authService } from '@/modules/account/services/authService';
import { giornalistiCorsoService } from '@/modules/giornalisti/services/giornalistiCorsoService';
import { relatoreSessioneService } from '@/modules/sessioni/services/relatoreSessioneService';
import { sessioneService } from '@/modules/sessioni/services/sessioneService';
import { debounce } from '@/services/_base/debounce';
import { loginStore } from '@/store/modules/login.store';
import { capitalizeFirstLetter } from '@/utils/utils';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { relatoriService } from '../../corsiRelatori/services/relatoriService';
import newRelatore from './newRelatore.vue';

@Component({
  components: {
    newRelatore
  }
})
export default class ListaRelatori extends Vue {
  @Prop()
  corso: corso.item;

  @Prop({ required: false, default: false })
  disabled: boolean;

  loading: boolean = false;
  relatori: relatore.item[] = [];
  relatoreSelezionato: string = null;

  showDialog: boolean = false;

  pagination: { pageSizes: number[], total: number, } = {
    pageSizes: [20, 40, 60],
    total: 0
  }

  query: relatore.queryParams = {
    anagraficaRelatoreId: null,
    search: null,
    utentiDiSistema: null,
    orderField: "NomeCompleto",
    orderDescending: false,
    page: 0,
    take: -1
  }
  user: any;

  @Watch('query.search')
  async queryChanged(n, o) {
    if (n !== o) {
      this.handleFilter();
    }
  }

  toAdd: relatore.item[] = [];
  toRemove: relatore.item[] = [];
  async created() {
    this.user = loginStore.state.userInfo || await authService.WhoAmI();
    await this.fetchData();
  }

  handleFilter() {
    this.loading = true;
    this.fetchDataDebounced(this);
  }

  private fetchDataDebounced = debounce(async (self: this) => {
    await self.fetchData()
  }, 400);

  async fetchData() {
    this.loading = true;
    this.pagination.total = await relatoriService.Count(this.corso.id, this.query);
    this.relatori = await relatoriService.List(this.corso.id, this.query);
    this.loading = false;
  }

  close(value?: relatore.item) {
    this.showDialog = false;
  }

  change(item: relatore.item) {
    this.showDialog = false;
    this.toAdd.push(item);
    // this.relatori.push(item);
    // this.fetchData();
  }

  // paginationSizeChange(size) {
  //   this.query.take = size;
  //   this.query.page = 1;
  //   this.fetchData();
  // }

  // paginationPageChange(page) {
  //   this.query.page = page;
  //   this.fetchData();
  // }

  sortChange({ column, prop, order }) {
    this.query.orderField = capitalizeFirstLetter(prop);
    this.query.orderDescending = order === 'descending';
    this.fetchData();
  }

  stateRowClassName({ row }: { row: relatore.item }) {
    if (this.toAdd.map(m => m.id).includes(row.id)) {
      return "relatore-to-add"
    }
    if (this.toRemove.map(m => m.id).includes(row.id)) {
      return "relatore-to-remove"
    }
    return 'relatore-to-nientedafare';
  }

  async handleUndo(item: relatore.item) {
    const idx = this.toRemove.map(m => m.id).indexOf(item.id);
    if (idx >= 0) this.toRemove.splice(idx, 1);
  }

  deleting: boolean = false;
  async handleDelete(item: relatore.item) {
    this.$msgbox({
      title: `Attenzione`,
      message: `Il relatore <strong>${item.nomeCompleto}</strong> verrà cancellato definitivamente. Continuare?`,
      showCancelButton: true,
      confirmButtonText: `Si`,
      cancelButtonText: `Annulla`,
      dangerouslyUseHTMLString: true,
      type: 'warning'
    }).then(async () => {
      this.deleting = true;
      const relatoreSessioni = await relatoreSessioneService.List(this.corso.id, { relatoreId: item.id } as relatoreSessione.queryParams);
      if (relatoreSessioni && relatoreSessioni.length > 0) {
        this.$alert(`Il relatore <strong>${item.nomeCompleto}</strong> che si è scelto di cancellare è <strong>relatore in ${relatoreSessioni.length} ${relatoreSessioni.length > 1 ? 'sessioni' : 'sessione'}</strong><br>Rimuovere il relatore da tutte le sessioni per poterlo cancellare`, 'Attenzione!', {
          confirmButtonText: 'Ok',
          type: "warning",
          dangerouslyUseHTMLString: true
        })
        this.deleting = false;
        return;
      }

      const notSavedIdx = this.toAdd.map(m => m.id).indexOf(item.id);
      if (notSavedIdx >= 0) {
        this.toAdd.splice(notSavedIdx, 1);
      } else {
        this.toRemove.push(item);
      }
      // relatoriService.Remove(this.corso.id, user.id).then(() => {
      //   this.fetchData();
      // }).catch(() => {
      //   this.$message({
      //     message: "Errore durante la rimozione del relatore",
      //     type: 'error',
      //     showClose: true
      //   });
      // })
      this.deleting = false;
    }).catch(err => {
      console.info(err);
    });
  }

  validate() {
    return this.pagination.total > 0
  }

  public get relatoriHasChanged() {
    return this.toAdd.length > 0 || this.toRemove.length > 0;
  }

  saveAllLoading: boolean = false;
  async saveAll() {
    if (this.corso.stato === 'inCompilazione' || this.corso.stato === "proposto") {
      this.saveAllLoading = true;
      await Promise.all(this.toAdd.map(m => relatoriService.Set(this.corso.id, m)));
      await Promise.all(this.toRemove.map(m => relatoriService.Remove(this.corso.id, m.id)));
      await this.fetchData();
      this.toAdd = [];
      this.toRemove = [];
      this.saveAllLoading = false;
    } else {
      this.$confirm(`Variando i Relatori del corso i membri del CTS riceveranno un avviso della modifica effettuata.<br><strong>Confermi la modifica?</strong>`, 'Attenzione!', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annulla',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(async () => {
        this.saveAllLoading = true;
        await Promise.all(this.toAdd.map(m => relatoriService.Set(this.corso.id, m)));
        await Promise.all(this.toRemove.map(m => relatoriService.Remove(this.corso.id, m.id)));
        await giornalistiCorsoService.NotificaModLuogoDataReferente({
          userId: this.user.id,
          corsoId: this.corso.id,
          luogo: false,
          dateCorso: false,
          relatori: true
        });
        await this.fetchData();
        this.toAdd = [];
        this.toRemove = [];
        this.saveAllLoading = false;
      });
    }
  }
}
