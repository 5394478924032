import { Vue, Component } from "vue-property-decorator";
import { corsiService } from "../services/corsiService";

import dialogNewCorso from "../components/newCorso/newCorso.vue";
import filterCorsi from "../components/filterCorsi/filterCorsi.vue";
import { capitalizeFirstLetter } from "@/utils/utils";
import { abilitatoAllaModificaCorso } from "./utils";
import { store } from "@/store";
import { enteService } from "@/modules/enti/service/enteService";
import { periodoFormativoService } from "@/modules/offertaFormativa/services/periodoFormativoService";
import { formatTime } from "@/filters";

@Component({
  components: {
    dialogNewCorso,
    filterCorsi
  }
})
export default class Corsi extends Vue {
  corsi: corso.item[] = [];
  loading: boolean = false;
  showManage = false;

  pagination: { pageSizes: number[]; total: number } = {
    pageSizes: [20, 40, 60],
    total: 0
  };

  get query(): corso.queryParams { return store.state.corsi.query; }

  get periodiFormativi(): periodoFormativo.item[] {
    return store.getters.periodiFormativi.list();
  };
  
  async created() {
    await this.fetchData()
  }

  getPeriodoFormativo(periodoFormativoId: string) {
    if (!periodoFormativoId || this.periodiFormativi.length <= 0) return {};
    return this.periodiFormativi.find(f => f.id === periodoFormativoId);
  }

  enti: Map<string, ente.item> = new Map<string, ente.item>();
  async fetchData() {
    this.loading = true;
    this.pagination.total = await corsiService.Count(this.query);
    const corsi = await corsiService.List(this.query);
    if (corsi.length > 0) {
      for (const corso of corsi) {
        if (!this.enti.has(corso.enteId)) {
          const ente = await enteService.Detail(corso.enteId);
          this.enti.set(corso.enteId, ente);
        }
        corso["periodoFormativo"] = this.getPeriodoFormativo(corso.periodoFormativoId);
      }
    }
    this.corsi = corsi;
    this.loading = false;
  }

  formatTime(value: any, option?: string) {
    return formatTime(value, option)
  }

  onAdd(corso: corso.item) {
    this.showManage = false;
    this.handleEdit(corso);
  }

  handleEdit(row: corso.item) {
    this.$router.push({ name: 'modificaCorso', params: { id: row.id } })
  }

  handleDelete(row: corso.item) {
    if (this.canRemove(row)) {
      this.$message({
        message: `Il corso <strong>${row.titolo}</strong> non può essere cancellato perché
            <br><strong>il suo stato non è ${this.$t(`corso.stati.inCompilazione`)}</strong> oppure perché 
            <br><strong>lo stato ${this.$t(`corso.stati.${row.stato}`)} non è più uno stato iniziale</strong>`,
        dangerouslyUseHTMLString: true,
        type: 'error',
        showClose: true,
        duration: 7200
      });
      return;
    }

    this.$msgbox({
      title: `Attenzione`,
      message: `Il corso <strong>${row.titolo}</strong> verrà cancellato definitivamente. Continuare?`,
      showCancelButton: true,
      confirmButtonText: `Si`,
      cancelButtonText: `Annulla`,
      dangerouslyUseHTMLString: true,
      type: 'warning'
    })
      .then(() => {
        corsiService
          .Remove(row.id)
          .then(() => this.fetchData())
          .catch(error => {
            console.error(error);
            this.$message({
              message: `Si è verificato un errore nella cancellazione del corso <strong>${row.titolo}</strong>.`,
              dangerouslyUseHTMLString: true,
              type: 'error',
              duration: 7200
            });
          });
      })
      .catch((e) => {
        console.info(e);
      });
  }

  handleViewIscritti(corso: corso.item) {
    this.$router.push({ name: "modificaoCorso", params: { id: corso.id } });
  }

  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
    this.fetchData();
  }

  paginationPageChange(page) {
    this.query.page = page;
    this.fetchData();
  }

  sortChange({ column, prop, order }) {
    this.query.orderField = capitalizeFirstLetter(prop);
    this.query.orderDescending = order === 'descending';
    this.fetchData();
  }

  onFilterChange(query) {
    store.actions.corsi.setQuery(query);
    this.fetchData();
  }

  onFilterClean(query) {
    store.actions.corsi.setQuery(query);
    this.fetchData();
  }

  private canRemove(row: corso.item) {
    return !(row.stato === 'inCompilazione' && row.storicoStato.filter(f => f.nuovoStato !== 'inCompilazione').length <= 0);
  }

  canEdit(row: corso.item) {
    return abilitatoAllaModificaCorso(row);
  }
}
