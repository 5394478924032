import { Vue, Component, Watch } from 'vue-property-decorator';
import { corsiService } from '../../services/corsiService';
import { ElForm } from 'element-ui/types/form';
import { periodoFormativoService } from '@/modules/offertaFormativa/services/periodoFormativoService';
import { pofService } from '@/modules/offertaFormativa/services/pianoOffertaFormativaService';
import { authService } from '@/modules/account/services/authService';
import { enteService } from '@/modules/enti/service/enteService';

import { loginStore } from '@/store/modules/login.store';

import SelectPeriodoFormativo from '@/modules/offertaFormativa/components/selectPeriodoFormativo.vue';
import { formatTime } from '@/filters';

@Component({
  components: { SelectPeriodoFormativo }
})
export default class NewCorso extends Vue {
  corso: corso.item = {
    titolo: null,
    pofId: null,
    numeroMinimoPartecipanti: 20,
    numeroMassimoPartecipanti: 50,
    continuativo: null,
    data: null,
    dataScadenza: null,
    dataInizioIscrizione: null,
    dataFineIscrizione: null,
    inProroga: false
  } as corso.item;

  periodiFormativi: periodoFormativo.item[] = [];
  periodoFormativo: periodoFormativo.item = null;
  showSelectPeriodiFormativi: boolean = false;

  pof: pianoOffertaFormativa.item = null;

  ente: ente.item = null;

  user: user.summary = null;
  async created() {
    this.user = loginStore.state.userInfo || await authService.WhoAmI();
    if (!this.user || (this.user && !this.user.anagraficaEnteId)) {
      this.$alert(`All'utente <strong>${this.user?.nomeCompleto}</strong> non è associato nessun Organo o Ente di cui questo è referente.<br>Contattare l'amministrazione per completare l'associazione dell'utenza con l'ente.`, 'Attenzione!', {
        confirmButtonText: 'Ok',
        type: "warning",
        dangerouslyUseHTMLString: true
      });
      this.$emit('cancel');
      return;
    }
    this.ente = await enteService.Detail(this.user.anagraficaEnteId);
  }

  @Watch('corso.data')
  async dataChanged(n, o) {
    if (n && n !== o && o !== undefined) {
      this.periodoFormativo = null;
      await this.computeCorsoData(this.corso.data);
    } else {
      if (!n && o) {
        this.corso.dataInizioIscrizione = null;
        this.corso.dataFineIscrizione = null;
        this.corso.pofId = null;
        this.corso.periodoFormativoId = null;
        this.corso.inProroga = false;
      }
    }
  }

  @Watch('corso.continuativo')
  async continuativoChanged(n, o) {
    if (n !== null && o !== null && n !== o) {
      await this.computeCorsoData(this.corso.data);
    }
  }

  async computeCorsoData(n: Date) {
    if (n) {
      this.corso.dataInizioIscrizione = new Date(new Date(n).setDate(new Date(n).getDate() - 14));
      this.corso.dataFineIscrizione = new Date(new Date(n).setDate(new Date(n).getDate() - 1));
      this.corso.dataScadenza = new Date(n);
      this.corso.periodoFormativoId = null;
      this.corso.inProroga = false;
      this.corso.pofId = null;

      if (this.periodoFormativo) {
        this.computeCorsoDataPeriodoFormativo(n);
        return;
      }

      const date: Date = typeof (n) === 'string' ? new Date(n) : n;
      const l = (await periodoFormativoService.GetByDate(date));
      this.periodiFormativi = l.filter(f => (f.stato === 'prorogato' || f.stato === 'corrente') && (!f.dataFineProroga || f.dataFineProroga.setHours(0, 0, 0, 0) >= date.setHours(0, 0, 0, 0)))

      if (!this.periodiFormativi || this.periodiFormativi.length <= 0) {
        this.corso.periodoFormativoId = null;
        this.corso.pofId = null;
        this.periodoFormativo = null;
        this.pof = null;
        return;
      } else if (this.periodiFormativi.length == 1) {
        this.periodoFormativo = this.periodiFormativi[0];
        this.computeCorsoDataPeriodoFormativo(n);
        return;
      }

      this.showSelectPeriodiFormativi = true;
    }
  }

  onConfirmSelectPeriodoFormativo(periodoFormativo: periodoFormativo.item) {
    this.periodoFormativo = periodoFormativo;
    this.computeCorsoDataPeriodoFormativo(this.corso.data);
  }

  async computeCorsoDataPeriodoFormativo(n: Date) {
    const date: Date = typeof (n) === 'string' ? new Date(n) : n;

    if (!this.periodoFormativo)
      return;

    this.corso.periodoFormativoId = this.periodoFormativo.id;
    if (!this.corso.continuativo || !this.ente) {
      this.pof = await pofService.GetByDate(new Date(date));
      this.corso.dataScadenza = new Date(date);
      this.corso.pofId = this.pof?.id;
    } else {
      this.corso.dataInizioIscrizione = null;
      this.corso.dataFineIscrizione = null;
      if (this.periodoFormativo) {
        if (this.ente.tipo != `etf` && this.ente.tipo != `azienda`) {
          this.corso.dataInizioIscrizione = new Date(this.periodoFormativo.dataInizio, 0, 1);
          this.corso.dataFineIscrizione = this.periodoFormativo.stato === 'prorogato' ? new Date(formatTime(this.periodoFormativo.dataFineProroga, 'YYYY-MM-DD')) : new Date(this.periodoFormativo.dataFine, 11, 31, 23, 59, 59);
          this.corso.dataScadenza = this.periodoFormativo.stato === 'prorogato' ? new Date(formatTime(this.periodoFormativo.dataFineProroga, 'YYYY-MM-DD')) : new Date(this.periodoFormativo.dataFine, 11, 31, 23, 59, 59);
        } else {
          this.corso.dataInizioIscrizione = new Date(date.getFullYear(), 0, 1);
          this.corso.dataFineIscrizione = this.periodoFormativo.stato === 'prorogato' ? new Date(formatTime(this.periodoFormativo.dataFineProroga, 'YYYY-MM-DD')) : new Date(date.getFullYear(), 11, 31, 23, 59, 59);
          this.corso.dataScadenza = this.periodoFormativo.stato === 'prorogato' ? new Date(formatTime(this.periodoFormativo.dataFineProroga, 'YYYY-MM-DD')) : new Date(date.getFullYear(), 11, 31, 23, 59, 59);
        }
      }
    }

    this.corso.inProroga = this.periodiFormativi.length > 1 && this.periodoFormativo.stato === "prorogato";
  }

  get formRules() {
    return {
      titolo: [{ required: true, trigger: 'blur', message: `Il campo "Titolo" è obbligatorio` }],
      data: [{ required: true, trigger: 'change', message: `Il campo "Data" è obbligatorio` }]
    };
  }

  saving = false;
  private async save() {
    // if (!this.corso.continuativo && !this.corso.pofId) {
    //   this.$message({
    //     message: "Non puoi proporre il corso. Nessun `Piano Offerta Formativa` associabile alla data selezionata",
    //     type: "error",
    //     showClose: true,
    //     center: true
    //   });
    //   return;
    // }

    if (this.corso.continuativo && (!this.corso.periodoFormativoId || this.corso.periodoFormativoId === '00000000-0000-0000-0000-000000000000')) {
      this.$message({
        message: "Non puoi proporre il corso. Nessun `Periodo Formativo` associabile alla data selezionata",
        type: "error",
        showClose: true,
        center: true
      });
      return;
    }

    (this.$refs.form as ElForm)?.validate(async valid => {
      if (valid && this.periodoFormativo) { // && (this.corso.continuativo || !this.corso.continuativo && this.pof)) {
        this.saving = true;
        if (this.user) {
          this.corso.referenteId = this.user?.id
          this.corso.enteId = this.ente?.id;
          this.corso.referente = {
            cognome: this.user.cognome,
            email: this.user.email,
            enteDescrizione: this.ente?.descrizione,
            id: this.user.id,
            nome: this.user.nome,
            nomeCompleto: this.user.nomeCompleto,
            phoneNumber: this.user.phoneNumber,
            anagraficaEnteId: this.user.anagraficaEnteId,
            anagraficaGiornalistaId: this.user.anagraficaGiornalistaId,
          } as corso.utenza;
        }
        corsiService
          .Set(this.corso)
          .then(corso => {
            this.$message({
              message: `Il corso <strong><i>${this.corso.titolo}</i></strong> è stato inserito con successo`,
              dangerouslyUseHTMLString: true,
              type: 'success',
              duration: 5 * 1000
            });
            this.$emit('change', corso);
            this.saving = false;
          })
          .catch(() => {
            this.$message({
              message: `Si è verificato un errore nell'inserimento del corso <strong><i>${this.corso.titolo}</i></strong>.<br>Verificare di aver compilato correttamente tutti i campi e riprovare.`,
              showClose: true,
              dangerouslyUseHTMLString: true,
              type: 'error',
              duration: 5 * 1000
            });
            this.saving = false;
          });
      }
    });
  }
}
