import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { anagraficaService } from "@/modules/anagrafica/services/anagraficaService";
import { relatoriService } from "../../corsiRelatori/services/relatoriService";
import GiornalistaAdempienteAutocomplete from "@/modules/anagrafica/components/giornalistaAdempienteAutocomplete/giornalistaAdempienteAutocomplete.vue";
import { ElForm } from "element-ui/types/form";
import { v4 as uuidv4 } from 'uuid';

@Component({
    components: {
        GiornalistaAdempienteAutocomplete
    }
})
export default class NewRelatore extends Vue {
    @Prop({ required: true })
    corsoId: string;

    manage: relatore.item = null;

    get formRules() {
        return {
            nome: [{ required: true, trigger: 'blur' }],
            cognome: [{ required: true, trigger: 'blur' }],
            professione: [{ required: true, trigger: 'blur' },
            { min: 5, max: 100, message: 'Lunghezza: min 5, max 100 caratteri', trigger: 'blur' }]
        };
    }

    get hasDisabled(): boolean {
        return this.manage && !!this.manage.anagraficaGiornalistaId
    }

    created() {
        this.initManage();
    }

    @Watch("manage.anagraficaGiornalistaId")
    async relatoreSelezionatoChanged(n, o) {
        if (n && n !== o) {
            const giornalista = await anagraficaService.Detail(this.manage.anagraficaGiornalistaId);
            this.manage = {
                id: uuidv4(),
                nome: giornalista.nome,
                cognome: giornalista.cognome,
                nomeCompleto: `${giornalista.nome} ${giornalista.cognome}`,
                email: giornalista.mail || giornalista.pec,
                telefono: giornalista.telefono,
                corsoId: this.corsoId,
                professione: 'Giornalista',
                anagraficaGiornalistaId: giornalista.id,
            } as relatore.item;
        } else {
            if (o) {
                this.initManage();
            }
        }
    }

    initManage() {
        this.manage = {
            id: uuidv4(),
            nomeCompleto: null,
            nome: null,
            cognome: null,
            email: null,
            telefono: null,
            corsoId: this.corsoId,
            professione: null,
            anagraficaGiornalistaId: null,
        } as relatore.item;
    }

    saving: boolean = false;
    async save() {
        (this.$refs.form as ElForm)?.validate(async valid => {
            if (valid) {
                this.manage.nomeCompleto = `${this.manage.nome} ${this.manage.cognome}`
                this.$emit("change", this.manage);
                // this.saving = true;
                // relatoriService.Set(this.corsoId, this.manage).then((result) => {
                //     this.saving = false;
                //     this.$emit('change', result);
                // }).catch(() => {
                //     this.saving = false;
                //     this.$message({
                //         message: `Errore durante l'inserimento del relatore ${this.manage.nome} ${this.manage.cognome}`,
                //         type: "error",
                //     });
                // });
            }
        });
    }
}