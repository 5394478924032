import { Vue, Component, Prop } from 'vue-property-decorator';
import { debounce } from '@/services/_base/debounce';
import { corsiService } from '@/modules/corsi/services/corsiService';
import { sessioneService } from '../services/sessioneService';
import { moduliService } from '@/modules/moduli/service/moduliService';

import ManageSessione from '../components/manage-sessione/manage-sessione.vue';

@Component({
  name: 'ListaSessioni',
  components: {
    ManageSessione
  }
})
export default class ListaSessioni extends Vue {
  @Prop({ required: true })
  corsoId: string

  @Prop()
  corsoData: string | Date

  @Prop({required: false, default: false})
  disabled: boolean;

  list: sessione.item[] = [] as sessione.item[];
  manage: sessione.item = null;
  manageVisible = false;

  pagination: { pageSizes: number[], total: number, } = {
    pageSizes: [10, 40, 60],
    total: 0
  }

  public query: sessione.queryParams = {
    page: 1,
    take: 10,
    search: null,
  };

  handleFilter() {
    this.loading = true;
    this.fetchDataDebounced(this);
  }

  private fetchDataDebounced = debounce(async (self: this) => {
    await self.fetchData()
  }, 400);


  corso: corso.item = null;
  moduli: Map<string, modulo.item> = new Map<string, modulo.item>();
  async created() {
    this.corso = await corsiService.Detail(this.corsoId);
    await this.fetchData();
  }

  async getModuli() {
    const moduli = await moduliService.List(this.corsoId);
    for (const modulo of moduli) {
      if (!this.moduli.has(modulo.id)) this.moduli.set(modulo.id, modulo);
    }
  }

  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
    this.fetchData();
  }

  paginationPageChange(page) {
    this.query.page = page;
    this.fetchData();
  }

  loading: boolean = false;
  async fetchData() {
    this.loading = true;
    this.pagination.total = await sessioneService.Count(this.corsoId, this.query);
    this.list = await sessioneService.List(this.corsoId, this.query);
    this.getModuli();
    this.loading = false;
  }

  handleEdit(row: sessione.item) {
    this.manage = row;
    this.manageVisible = true;
  }

  handleRemove(row: sessione.item) {
    this.$msgbox({
      title: `Attenzione`,
      message: `La sessione del corso <strong>${this.corso.titolo}</strong> verrà cancellata definitivamente. Continuare?`,
      showCancelButton: true,
      confirmButtonText: `Si`,
      cancelButtonText: `Annulla`,
      dangerouslyUseHTMLString: true,
      type: 'warning'
    })
      .then(() => {
        sessioneService
          .Remove(this.corsoId, row.id)
          .then(() => this.fetchData())
          .catch(error => {
            this.$message({
              message: `Si è verificato un errore nella cancellazione della sessione del corso <strong>${this.corso.titolo}</strong>`,
              dangerouslyUseHTMLString: true,
              type: 'error',
              duration: 7200
            });
          });
      })
      .catch((e) => {
        console.info(e);
      });
  }

  async onChange(elem: sessione.item) {
    this.fetchData();
    this.manageVisible = false;
    this.manage = null;
  }

  onClose() {
    this.manageVisible = false;
    this.manage = null;
  }

  validate() {
    return this.pagination.total > 0
  }
}
