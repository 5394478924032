import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { debounce } from '@/services/_base/debounce';
import { iscrittoService } from '../services/iscrittoService';
import { corsiService } from '@/modules/corsi/services/corsiService';

import GiornalistaAutocomplete from "@/modules/anagrafica/components/giornalistaAutocomplete/giornalistaAutocomplete.vue";
import ImportazionePartecipanti from "../components/importazionePartecipanti/importa.vue";
import { formatTime } from '@/filters';
import { loginStore } from '@/store/modules/login.store';
import { authService } from '@/modules/account/services/authService';
import { abilitatoAllaModificaCorsoDaStato } from '@/modules/corsi/views/utils';
import { capitalizeFirstLetter } from '@/utils/utils';

@Component({
  name: 'ListaIscritti',
  components: {
    GiornalistaAutocomplete,
    ImportazionePartecipanti
  }
})
export default class ListaIscritti extends Vue {
  @Prop({ required: true })
  corsoId: string

  @Prop({ required: false, default: false })
  disabled: boolean

  @Prop()
  cancellato?: boolean;

  list: iscritto.item[] = [] as iscritto.item[];
  manage: iscritto.item = null;
  manageVisible = false;

  pagination: { pageSizes: number[], total: number, } = {
    pageSizes: [20, 40, 60],
    total: 0
  }

  public query: iscritto.queryParams = {
    page: 1,
    take: 20,
    anagraficaGiornalistaId: null,
    ammesso: null,
    cancellato: this.cancellato,
    frequentato: null,
    orderField: 'DataIscrizione',
    orderDescending: false,
  };
  
  get cancellazioneIscrittoPossibile(): boolean {
    return new Date(new Date().setHours(0, 0, 0, 0)) <= (this.corso.continuativo ?
      new Date(this.corso.dataFineIscrizione.setHours(0, 0, 0, 0)) :
      new Date(this.corso.data.setHours(0, 0, 0, 0)));
  }

  handleFilter() {
    this.loading = true;
    this.fetchDataDebounced(this);
  }

  private fetchDataDebounced = debounce(async (self: this) => {
    await self.fetchData()
  }, 400);


  user: user.summary = null;
  corso: corso.item = null;
  async created() {
    this.user = loginStore.state.userInfo || await authService.WhoAmI();
    this.corso = await corsiService.Detail(this.corsoId);
    await this.fetchData();
  }


  get enableImportPartecipanti() {
    return abilitatoAllaModificaCorsoDaStato(this.corso, "inErogazione") || this.$can('CORSO.forcedImportPartecipanti')
  }

  get enableToolIscritti() {
    return this.corso.data.setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
      && (abilitatoAllaModificaCorsoDaStato(this.corso, "pubblicato") || abilitatoAllaModificaCorsoDaStato(this.corso, "inErogazione"))
  }

  sortChange({ column, prop, order }) {
    console.log(prop)
    this.query.orderField = capitalizeFirstLetter(prop);
    this.query.orderDescending = order === 'descending';
    console.log(this.query)
    this.fetchData();
  }

  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
    this.fetchData();
  }

  paginationPageChange(page) {
    this.query.page = page;
    this.fetchData();
  }

  loading: boolean = false;
  async fetchData() {
    this.loading = true;
    this.pagination.total = await iscrittoService.Count(this.corsoId, this.query);
    this.list = await iscrittoService.List(this.corsoId, this.query);
    this.loading = false;
  }

  handleEdit(row: iscritto.item) {
    this.manage = row;
    this.manageVisible = true;
  }

  handleRemove(row: iscritto.item) {
    if (this.cancellazioneIscrittoPossibile) {
      this.$prompt(`Attenzione, stai per rimuovere l'iscrizione al corso <strong>${this.corso.titolo}</strong>. <br/><span class="big">ATTENZIONE: Se confermi la cancellazione non sarà possibile iscriversi di nuovo a questo corso! </span>`, `Cancellazione iscrizione al corso`, {
        confirmButtonText: 'Ok',
        cancelButtonText: 'Annulla',
        dangerouslyUseHTMLString: true,
        inputType: 'textarea',
        inputPlaceholder: 'Inserisci la motivazione per la cancellazione',
        inputPattern: /^\s*\S.*$/,
        inputErrorMessage: `E' obbligatorio inserire la motivazione per cui l'iscrizione viene cancellata`
      }).then(({ value }: any) => {
        iscrittoService
          .Remove(this.corsoId, row.anagraficaGiornalistaId, value)
          .then(() => this.fetchData())
          .catch(error => {
            this.$message({
              message: `Si è verificato un errore nella cancellazione dell'iscrizione al corso <strong>${this.corso.titolo}</strong>`,
              dangerouslyUseHTMLString: true,
              type: 'error',
              duration: 7200
            });
          });
      }).catch(() => { });
    }
    else {
      this.$alert(
        `La cancellazione delle iscrizioni è <u><strong>possibile solo entro</strong></u><br />
        la data del corso (${formatTime(this.corso.data, 'DD/MM/YYYY')}).<br />`,
        `Non è possibile cancellare l'iscrizione`,
        {
          dangerouslyUseHTMLString: true
        }
      )
    }
  }

  handleGiustificato(row: iscritto.item) {
      this.$prompt(`Stai per giustificare l'assenza al corso <strong>${this.corso.titolo}</strong>`, `Giustificazione assenza al corso`, {
        confirmButtonText: 'Ok',
        cancelButtonText: 'Annulla',
        dangerouslyUseHTMLString: true,
        inputType: 'textarea',
        inputPlaceholder: 'Inserisci la motivazione per la giustificazione',
        inputPattern: /^\s*\S.*$/,
        inputErrorMessage: `E' obbligatorio inserire la motivazione per cui l'assenza viene giustificata`
      }).then(({ value }: any) => {
        iscrittoService
          .SetGiustificazione(this.corsoId, row.anagraficaGiornalistaId, row.giustificato)
          .then(() => this.fetchData())
          .catch(() => {
            this.$message({
              message: `Si è verificato un errore nella giustificazione dell'assenza al corso <strong>${this.corso.titolo}</strong>`,
              dangerouslyUseHTMLString: true,
              type: 'error',
              duration: 7200
            });
          });
      }).catch(() => { });
  }

  async onChange(elem: iscritto.item) {
    this.fetchData();
    this.manageVisible = false;
  }

  onClose() {
    this.manageVisible = false;
  }

  importVisible = false;
  async onImportComplete() {
    this.fetchData();
    this.importVisible = false;
  }

  onImportClose() {
    this.importVisible = false;
  }
}
