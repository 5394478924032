import Vue from "vue";
import * as XLSX from 'xlsx';

/* load 'fs' for readFile and writeFile support */
import * as fs from 'fs';
XLSX.set_fs(fs);

import { Component, Prop, Watch } from "vue-property-decorator";
import { ElUpload } from "element-ui/types/upload";
import { corsiService } from "@/modules/corsi/services/corsiService";

import { anagraficaService } from "@/modules/anagrafica/services/anagraficaService";
import { importaPartecipantiUtils } from "./importaPartecipantiUtils";
import { iscrittoService } from "../../services/iscrittoService";
import { periodoFormativoService } from "@/modules/offertaFormativa/services/periodoFormativoService";
import { enteService } from "@/modules/enti/service/enteService";
import settings from "@/settings";
import { creditiService } from "@/modules/offertaFormativa/services/creditiService";

@Component({})
export default class ImportazionePartecipanti extends Vue {
  @Prop({ required: true })
  corsoId: string;

  loading: { dataTable: boolean, file: boolean, import: boolean } = { dataTable: false, file: false, import: false };
  file: File;
  sheet: sheets.item = null;

  validWoorkBookHeader: boolean = true;
  stepIndex: number = 0;

  limit: number = 20;

  corso: corso.item = null;
  periodoFormativo: periodoFormativo.item = null;
  ente: ente.item = null;
  fileDetail: { name: string; createDate: Date; createUser: string; lastUpdateDate: Date; lastUpdateUser: string; } = null;

  breakLoading: boolean = false;

  rowLimit: number = 1000;
  rowLimitExceeded: boolean = false;
  get codiciFiscaliDuplicati() {
    if (!this.sheet || (this.sheet.rows || []).length <= 0) return [];

    const codiciFiscali = this.sheet.rows.map(m => m.codiceFiscale);
    return codiciFiscali.filter((item, index) => codiciFiscali.indexOf(item) != index)
  }

  get filteredRows(): iscritto.partecipante[] {
    if (!this.sheet) return [];

    let rows = this.sheet.rows;
    if (this.sheet.filterText && this.sheet.filterText !== "") {
      rows = rows.filter(w => w.codiceFiscale.toUpperCase().includes(this.sheet.filterText.toUpperCase()));
    }

    if (this.sheet.filterState && this.sheet.filterState === "complete") {
      rows = rows.filter(w => w.valid == true);
    }

    if (this.sheet.filterState && this.sheet.filterState === "warning") {
      rows = rows.filter(w => w.duplicated == true || w.dateOutOfRange == true);
    }

    if (this.sheet.filterState && this.sheet.filterState === "error") {
      rows = rows.filter(w => w.valid == false);
    }

    return rows;
  }

  get loadedRows(): iscritto.partecipante[] {
    const start = (this.sheet.page - 1) * this.sheet.limit;
    const end = start + this.sheet.limit;
    this.sheet.total = this.filteredRows.length;
    return this.filteredRows.slice(start, end);
  }

  async created() {
    this.corso = await corsiService.Detail(this.corsoId);
    if (this.corso?.periodoFormativoId) {
      this.periodoFormativo = await periodoFormativoService.Detail(this.corso.periodoFormativoId);
    }
    this.ente = await enteService.Detail(this.corso.enteId);
  }

  get dataInizioPeriodoFormativo() {
    if (!this.periodoFormativo) return null;
    return new Date(this.periodoFormativo.dataInizio, 0, 1, 0, 0, 0)
  }

  get dataFinePeriodoFormativo() {
    if (!this.periodoFormativo) return null;
    return new Date(this.periodoFormativo.dataFine, 11, 31, 23, 59, 59)
  }

  beforeDestroy() {
    this.breakLoading = true;
  }

  next() {
    if (this.stepIndex++ > 1) return;
  }

  back() {
    if (this.stepIndex-- <= 0) return;
  }

  async importa() {
    this.loading.import = true;
    const chunk = 10;

    const loadingFullScreen = this.$loading({
      lock: true,
      text: `Caricamento dei partecipanti per il corso ${this.corso.titolo} in lavorazione...`,
      background: "rgba(0,0,0, 0.7)"
    });

    for (let index = 0; index < Math.ceil(this.sheet.rows.length / chunk); index++) {
      const skip = (index * chunk);
      const take = Math.min((((index + 1) * chunk)), (this.sheet.rows.length));

      (loadingFullScreen as any).setText(`Caricamento dei partecipanti ${take} su ${this.sheet.rows.length} per il corso ${this.corso.titolo}`);
      loadingFullScreen.$forceUpdate();


      const chunkedRows = this.sheet.rows.slice(skip, take);
      for (const el of chunkedRows) {
        if (!el.haFrequentato) {
          el.dataAssenza = el.dataConfermaCrediti ? new Date(el.dataConfermaCrediti.getTime()) : new Date();
          el.assente = true;
          el.dataConfermaCrediti = null;
        } else {
          el.dataAssenza = null;
          el.assente = false;
          el.giustificato = false;
        }
      }
      await iscrittoService.Partecipanti(this.corsoId, chunkedRows);
    }

    this.loading.import = false;
    loadingFullScreen.close();
    this.$emit("complete");

    // iscrittoService.Partecipanti(this.corsoId, this.sheet.rows).then(
    //   () => {
    //     this.loading.import = false;
    //     loadingFullScreen.close();
    //     this.$emit("complete");
    //   },
    //   (error) => {
    //     this.loading.import = false;
    //     loadingFullScreen.close();
    //     this.$message({
    //       message: `Si è verificato un errore nell'importazione dei partecipanti al corso <strong>${this.corso.titolo}</strong>`,
    //       showClose: true,
    //       dangerouslyUseHTMLString: true,
    //       type: "error"
    //     });
    //   }
    // );
  }

  fixData(data) {
    let o = "";
    let l = 0;
    let w = 10240;
    for (l; l < data.byteLength / w; ++l)
      o += String.fromCharCode.apply(
        null,
        new Uint8Array(data.slice(l * w, l * w + w))
      );
    o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
    return o;
  }

  readedFile: XLSX.WorkBook = null;
  parseFiles(file: File) {
    this.loading.file = true;
    this.file = file;
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = reader.result;
      const fixedData = this.fixData(data);
      const workbook = XLSX.read(btoa(fixedData), { type: "base64" });
      this.fileDetail = {
        name: this.file.name,
        createDate: workbook.Props ? workbook.Props.CreatedDate : null,
        createUser: workbook.Props ? workbook.Props.Author : null,
        lastUpdateDate: workbook.Props ? workbook.Props.ModifiedDate : null,
        lastUpdateUser: workbook.Props ? workbook.Props.LastAuthor : null
      };
      this.readedFile = workbook;
    };
    reader.readAsArrayBuffer(this.file);
  }


  @Watch("readedFile")
  async readWorkSheetChange(n: XLSX.WorkBook, o: XLSX.WorkBook) {
    this.sheet = null;
    if (n && n !== o && (n.SheetNames || []).length > 0) {
      this.loading.file = true;
      const sheetName = n.SheetNames[0];
      const sheet = n.Sheets[sheetName];

      const requiredColumns = ['codiceFiscale', 'dataConfermaCrediti', 'haFrequentato'];
      const headerLoadedColumns = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0] as string[];
      this.validWoorkBookHeader = headerLoadedColumns && requiredColumns.every(s => headerLoadedColumns.includes(s))
      if (!this.validWoorkBookHeader) {
        this.loading.file = false;
        return;
      }

      const key = importaPartecipantiUtils.normalize(sheetName);
      await this.parseRows(sheet, (rows) => {
        this.sheet = {
          key: key,
          name: sheetName,
          limit: this.limit,
          page: 1,
          rows: rows,
          filterText: "",
          filterState: "all",
          total: 0,
          complete: 0,
          warning: 0,
          error: 0,
        };
        this.reloadSheetStatisticData(this.sheet);
        this.loading.file = false;
      });
    }
  }


  originalShitRowsLength: number = 0;
  loadingFileRowsProcessing: string = "";
  giornalisti: giornalisti.minimized[] = [];
  creditiAziendali: crediti.creditiAziendali[] = [];
  async parseRows(page: XLSX.WorkSheet, callback: Function) {
    this.loadingFileRowsProcessing = "Caricamento del file in corso ..."
    let sheetToJson = XLSX.utils.sheet_to_json<sheets.presenza>(page);

    if (!sheetToJson || sheetToJson.length <= 0) return [];

    if (sheetToJson.length > this.rowLimit) {
      this.rowLimitExceeded = true;
      sheetToJson = sheetToJson.slice(0, this.rowLimit)
    }

    this.loadingFileRowsProcessing = "Caricamento anagrafiche giornalisti in corso..."
    const codiciFiscali = [...new Set(sheetToJson.map(m => m.codiceFiscale))];
    this.giornalisti = await anagraficaService.MinimizedFromFiscalCodes({ page: 0, take: -1, codiciFiscali })
    if (this.giornalisti && this.giornalisti.length > 0 && (!this.ente || this.ente.tipo === 'azienda')) {
      this.creditiAziendali = await creditiService.GetCreditiAziendali(this.corso.periodoFormativoId, this.giornalisti.map(m => m.id));
    }

    this.originalShitRowsLength = sheetToJson.length;
    let rows: iscritto.partecipante[] = [];

    this.loadingFileRowsProcessing = `Inizializzazione verifica dati in corso...`
    for (let index = 0; index < sheetToJson.length; index++) {
      if (this.breakLoading) break;
      rows.push(await importaPartecipantiUtils.parsePartecipante(sheetToJson[index], this.corso, this.ente, this.giornalisti, this.creditiAziendali));
      this.loadingFileRowsProcessing = `Verifica della partecipazione ${index + 1} su ${this.originalShitRowsLength} da importare`
    }

    callback.call(null, rows);
  }

  filterRows(filter, sheet) {
    sheet.limit = this.limit;
    sheet.page = 1;
    sheet.filterState = filter;
  }

  pageSizeChange(val, sheet) {
    sheet.limit = val;
    sheet.page = 1;
  }

  pageCurrentChange(val, sheet) {
    sheet.page = val;
  }

  handleUpload(file) {
    this.clear();
    this.parseFiles(file.raw);
  }

  private clear() {
    this.sheet = null;
    this.rowLimitExceeded = false;
    this.validWoorkBookHeader = false;
    if (this.$refs.xlsxUploader)
      (this.$refs.xlsxUploader as ElUpload).clearFiles();
  }

  private reloadSheetStatisticData(sheet: sheets.item) {
    sheet.rows.forEach(row => {

      Object.assign(row, importaPartecipantiUtils.checkPresenza(row, this.giornalisti, this.corso, this.ente, this.creditiAziendali));

      if (this.codiciFiscaliDuplicati.includes(row.codiceFiscale)) {
        row.duplicated = true;
      }

      if (row.dataConfermaCrediti) {
        row.dataConfermaCrediti = new Date(row.dataConfermaCrediti);

        if (typeof (row.dataConfermaCrediti) === "string")
          row.dataConfermaCrediti = new Date(row.dataConfermaCrediti);

        row.dateOutOfRange = this.dataInizioPeriodoFormativo && this.dataFinePeriodoFormativo && (this.dataInizioPeriodoFormativo > row.dataConfermaCrediti || this.dataFinePeriodoFormativo < row.dataConfermaCrediti);
      }
    })
    sheet.total = sheet.rows.length;
    sheet.complete =
      sheet.rows.length <= 0
        ? 0
        : (sheet.rows as any).filter(v => v.valid == true).length;
    sheet.warning =
      sheet.rows.length <= 0
        ? 0
        : (sheet.rows as any).filter(v => v.duplicated == true || v.dateOutOfRange == true).length;
    sheet.error =
      sheet.rows.length <= 0
        ? 0
        : (sheet.rows as any).filter(v => v.valid == false).length;
  }

  get validSheet() {
    return this.sheet.error <= 0
  }

  dataIngressoBlacklistGiornalista(anagraficaGiornalistaId: string): Date {
    return this.giornalisti.find(f => f.id === anagraficaGiornalistaId)?.dataIngressoBlacklist;
  }

  dataUscitaBlacklistGiornalista(anagraficaGiornalistaId: string): Date {
    var d = this.dataIngressoBlacklistGiornalista(anagraficaGiornalistaId);
    if (d === null) return null;
    return new Date(d.getTime() + (settings.appSetting.blacklist.durataInGiorni * 24 * 60 * 60 * 1000));
  }

  checkGiornalistaInBlacklist(row: iscritto.partecipante) {
    const dataIngressoBlacklist = this.dataIngressoBlacklistGiornalista(row.anagraficaGiornalistaId)
    if (!dataIngressoBlacklist) return false;

    const dataUscitaBlacklist = this.dataUscitaBlacklistGiornalista(row.anagraficaGiornalistaId)

    const d = this.corso.continuativo ? row.dataConfermaCrediti : this.corso.data;
    const dataConfermaCrediti: Date = typeof (d) === 'string' ? new Date(d) : d;

    return row.contatoreBlacklist >= settings.appSetting.blacklist.assenzePerIngresso
      && dataIngressoBlacklist.setHours(0, 0, 0, 0) <= dataConfermaCrediti.setHours(0, 0, 0, 0)
      && dataUscitaBlacklist.setHours(0, 0, 0, 0) >= dataConfermaCrediti.setHours(0, 0, 0, 0)
  }

  validateRowClassName({ row, index }: { row: iscritto.partecipante, index: number }) {
    if (!row.valid) {
      return "danger-row";
    } else {
      if (row.duplicated || row.dateOutOfRange) {
        return "warning-row";
      } else {
        return "success-row";
      }
    }
  }

  tempSaveRow: iscritto.partecipante = null;
  handleEdit(row: iscritto.partecipante) {
    this.tempSaveRow = Object.assign({}, row);
    row.edit = true;
  }

  async onEditConfirm(row: iscritto.partecipante) {
    const value = await importaPartecipantiUtils.checkPresenza(row, this.giornalisti, this.corso, this.ente, this.creditiAziendali);
    Object.assign(row, value);
    this.reloadSheetStatisticData(this.sheet);
    this.tempSaveRow = null;
    row.edit = false;
  }

  onEditCancel(row: iscritto.partecipante) {
    Object.assign(row, this.tempSaveRow);
    this.reloadSheetStatisticData(this.sheet);
    this.tempSaveRow = null;
    row.edit = false;
  }

  handleDelete(elem) {
    var index = this.sheet.rows.indexOf(elem);
    this.$msgbox({
      title: "Attenzione",
      message:
        "La riga che si è scelto di eliminare dall'importazione verrà cancellata definitivamente. Continuare?",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Annulla",
      type: "warning"
    })
      .then(action => {
        if (elem && this.sheet && index !== undefined && index !== null) {
          this.sheet.rows.splice(index, 1);
          this.reloadSheetStatisticData(this.sheet);
        }
      })
      .catch(() => { });
  }

  async handleDownloadEmpty() {
    var wb = XLSX.utils.book_new();
    var iscritti = await iscrittoService.List(this.corsoId, { cancellato: false })
    var data = iscritti.map(x => {
      return {
        'nomeCompleto': x.nomeCompleto,
        'codiceFiscale': x.codiceFiscale,
        'dataConfermaCrediti': x.dataConfermaCrediti !== undefined ? x.dataConfermaCrediti : null,
        'haFrequentato': x.giustificato ? 'giustificato' : x.assente ? 'no' : (x.haFrequentato == null || x.haFrequentato == undefined) ? '' : x.haFrequentato ? 'si' : 'no',
      }
    })
    if (data.length == 0) {
      data.push({
        'nomeCompleto': "",
        'codiceFiscale': "",
        'dataConfermaCrediti': null,
        'haFrequentato': "",
      })
    }
    var sorted = data.sort((x, y) => { return x.nomeCompleto.localeCompare(y.nomeCompleto) })
    var sheetRows = XLSX.utils.json_to_sheet(sorted);

    XLSX.utils.book_append_sheet(wb, sheetRows, "Partecipanti");
    XLSX.writeFile(wb, "caricamento-partecipanti-template.xlsx");
  }
}
