import Vue from "vue";
import Component from "vue-class-component";

import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Typography from '@tiptap/extension-typography'
import Document from '@tiptap/extension-document'
import Link from '@tiptap/extension-link'
import Text from '@tiptap/extension-text'
import Code from '@tiptap/extension-code'
// import { SmilieReplacer } from '@/helpers/smiles'
import { Prop, Watch } from "vue-property-decorator";

@Component({
  components: { EditorContent }
})
export default class TextEditor extends Vue {

  @Prop()
  value: string;

  @Prop({ default: false })
  disabled: boolean;

  @Prop({ default: true })
  showToolBox: boolean;

  get Value() { return this.value; }
  set Value(v) { this.$emit('input', v); }

  // @Watch('value')
  // valueChanged(v) {
  //   this.editor.commands.setContent(v);
  // }

  editor: Editor = null;

  @Watch('disabled')
  disablechanged(n,o){
    if(n != o){
      this.editor.setEditable(!n);
    }
  }

  async mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Highlight,
        Typography,
        Document,
        Text,
        Code,
        Link.configure({
          openOnClick: false
        })
      ], content: this.Value
    });
    this.editor.setEditable(!this.disabled);

    this.editor.on("update", (ee) => {
      if(this.disabled) return;
      this.Value = ee.editor.getHTML();
    });
  }


  async beforeDestroy() {
    this.editor.destroy();
  }

  setLink() {
    const previousUrl = this.editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .unsetLink()
        .run()

      return
    }

    // update link
    this.editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run()
  }

}