var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('el-dialog',{attrs:{"visible":true,"destroy-on-close":true,"close-on-click-modal":false,"close-on-press-escape":false,"width":"640px"},on:{"close":function($event){return _vm.$emit('cancel')}}},[_c('template',{slot:"title"},[_vm._v(" Inserisci un nuovo corso ")]),_c('div',{staticClass:"new-corso-container"},[_c('el-form',{ref:"form",attrs:{"label-position":"top","rules":_vm.formRules,"model":_vm.corso}},[_c('el-form-item',{staticClass:"title",attrs:{"label":"Titolo","prop":"titolo"}},[_c('el-input',{attrs:{"type":"text","label":"Titolo corso"},model:{value:(_vm.corso.titolo),callback:function ($$v) {_vm.$set(_vm.corso, "titolo", $$v)},expression:"corso.titolo"}})],1),_c('div',{staticClass:"form-item-group"},[_c('el-form-item',{attrs:{"prop":"data"}},[_c('template',{slot:"label"},[_vm._v(" Data del corso ")]),_c('el-date-picker',{attrs:{"type":"date","format":"dd/MM/yyyy","value-format":"yyyy-MM-dd","placeholder":"Seleziona una data","label":"Data del corso"},model:{value:(_vm.corso.data),callback:function ($$v) {_vm.$set(_vm.corso, "data", $$v)},expression:"corso.data"}}),_c('div',{staticClass:"associated-pf-pof-container"},[(_vm.corso.data && _vm.periodoFormativo)?_c('el-tag',{attrs:{"type":_vm.periodoFormativo.stato === 'prorogato' ? 'warning' : _vm.periodoFormativo.stato === 'chiuso' ? 'info' : 'success'}},[_c('span',[_vm._v(_vm._s(_vm.periodoFormativo.nome)+" "),_c('strong',[_vm._v("[ "+_vm._s(`${_vm.$t(`periodoFormativo.stati.${_vm.periodoFormativo.stato}`)}`.toLowerCase())+" ]")])]),(_vm.periodiFormativi && _vm.periodiFormativi.length > 1)?_c('el-tooltip',{attrs:{"content":`Cambia Periodo Formativo`}},[_c('el-button',{attrs:{"type":"text"},on:{"click":() => {
                        _vm.periodoFormativo = null;
                        _vm.computeCorsoData(_vm.corso.data);
                      }}},[_c('i',{staticClass:"fas fa-sync-alt"})])],1):_vm._e()],1):_c('el-tag',{attrs:{"type":"danger"}},[_vm._v(" Nessun Periodo Formativo associabile alla data selezionata "),(_vm.periodiFormativi && _vm.periodiFormativi.length > 1)?_c('el-tooltip',{attrs:{"content":`Cambia Periodo Formativo`}},[_c('el-button',{attrs:{"type":"text"},on:{"click":() => {
                        _vm.periodoFormativo = null;
                        _vm.computeCorsoData(_vm.corso.data);
                      }}},[_c('i',{staticClass:"fas fa-sync-alt"})])],1):_vm._e()],1),(_vm.corso.data && _vm.pof && !_vm.corso.continuativo)?_c('el-tag',{staticClass:"pof",attrs:{"type":"success"}},[_vm._v(_vm._s(_vm.pof.nome))]):(_vm.corso.data && !_vm.corso.continuativo)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v(" Nessun Piano Offerta Formativa associabile alla data selezionata ")]):_vm._e()],1)],2),_c('el-form-item',{attrs:{"label":" Corso On Demand"}},[_c('el-switch',{model:{value:(_vm.corso.continuativo),callback:function ($$v) {_vm.$set(_vm.corso, "continuativo", $$v)},expression:"corso.continuativo"}})],1)],1)],1)],1),_c('template',{slot:"footer"},[_c('el-button',{attrs:{"aria-label":"Annulla creazione corso"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Annulla")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.saving,"aria-label":"Aggiungi corso"},on:{"click":_vm.save}},[_vm._v("Aggiungi")])],1)],2),(_vm.showSelectPeriodiFormativi)?_c('selectPeriodoFormativo',{attrs:{"periodiFormativi":_vm.periodiFormativi,"periodoFormativo":_vm.periodoFormativo},on:{"confirm":_vm.onConfirmSelectPeriodoFormativo,"close":() => (_vm.showSelectPeriodiFormativi = false)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }