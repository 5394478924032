import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { debounce } from '@/services/_base/debounce';
import { corsiService } from '@/modules/corsi/services/corsiService';
import { sessioneService } from '@/modules/sessioni/services/sessioneService'
import { presenzaService } from '../services/presenzaService';

// import ManagePresenza from '../components/managePresenza.vue';
import GiornalistaAutocomplete from "@/modules/anagrafica/components/giornalistaAutocomplete/giornalistaAutocomplete.vue";
import SessioneAutocomplete from '@/modules/sessioni/components/sessione-autocomplete.vue';
import ManagePresenza from '../components/managePresenza/managePresenza.vue';
import { loginStore } from '@/store/modules/login.store';
import { authService } from '@/modules/account/services/authService';
import { abilitatoAllaModificaCorsoDaStato } from '@/modules/corsi/views/utils';

@Component({
  name: 'ListaPresenze',
  components: {
    ManagePresenza,
    GiornalistaAutocomplete,
    SessioneAutocomplete
  }
})
export default class ListaPresenze extends Vue {
  @Prop({ required: true })
  corsoId: string

  @Prop()
  sessioneId: string

  @Prop({ required: false, default: false })
  disabled: boolean

  list: presenza.item[] = [] as presenza.item[];
  manage: presenza.item = null;
  manageVisible = false;

  pagination: { pageSizes: number[], total: number, } = {
    pageSizes: [20, 40, 60],
    total: 0
  }

  public query: presenza.queryParams = {
    page: 1,
    take: 20,
    anagraficaGiornalistaId: null,
    sessioneId: null,
  };

  handleFilter() {
    this.loading = true;
    this.fetchDataDebounced(this);
  }

  private fetchDataDebounced = debounce(async (self: this) => {
    await self.fetchData()
  }, 400);

  user: user.summary = null;
  corso: corso.item = null;
  sessioni: Map<string, sessione.item> = new Map<string, sessione.item>();
  async created() {
    this.user = loginStore.state.userInfo || await authService.WhoAmI();
    this.corso = await corsiService.Detail(this.corsoId);

    const sessioni = await sessioneService.List(this.corsoId);
    for (const sessione of sessioni) {
      if (!this.sessioni.has(sessione.id)) this.sessioni.set(sessione.id, sessione);
    }

    if (this.sessioneId) this.query.sessioneId = this.sessioneId
    await this.fetchData();
  }


  get enableInsertPresenze() {
    return !this.corso.continuativo && abilitatoAllaModificaCorsoDaStato(this.corso, "inErogazione")
  }


  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
    this.fetchData();
  }

  paginationPageChange(page) {
    this.query.page = page;
    this.fetchData();
  }

  loading: boolean = false;
  async fetchData() {
    this.loading = true;
    this.pagination.total = await presenzaService.Count(this.corsoId, this.query);
    this.list = await presenzaService.List(this.corsoId, this.query);
    this.loading = false;
  }

  async handleUscita(row: presenza.item) {
    this.$msgbox({
      title: `Attenzione`,
      message: `Verrà registrata l'uscita dalla sessione l'iscritto selezionato. Continuare?`,
      showCancelButton: true,
      confirmButtonText: `Si`,
      cancelButtonText: `Annulla`,
      dangerouslyUseHTMLString: true,
      type: 'warning'
    })
      .then(async () => {
        this.loading = true;
        presenzaService.SetUscita(row.corsoId, row.anagraficaGiornalistaId, row.sessioneId).then((result) => {
          this.$message({
            message: "Registrazione dell'uscita dalla sessione avvenuta con successo",
            type: "success"
          });
          Object.assign(row, result);
        }).catch(() => {
          this.$message({
            message: "Si è verificato un problema nella registrazione della presenza alla sessione del corso",
            type: "error"
          });
        });
        this.loading = false
      }).catch(() => { });
  }

  handleRemove(row: presenza.item) {
    this.$msgbox({
      title: `Attenzione`,
      message: `La presenza al corso <strong>${this.corso.titolo}</strong> verrà cancellato definitivamente. Continuare?`,
      showCancelButton: true,
      confirmButtonText: `Si`,
      cancelButtonText: `Annulla`,
      dangerouslyUseHTMLString: true,
      type: 'warning'
    })
      .then(() => {
        presenzaService
          .Remove(this.corsoId, row.anagraficaGiornalistaId, row.sessioneId)
          .then(() => this.fetchData())
          .catch(error => {
            this.$message({
              message: `Si è verificato un errore nella cancellazione della presenza al corso <strong>${this.corso.titolo}</strong>`,
              dangerouslyUseHTMLString: true,
              type: 'error',
              duration: 7200
            });
          });
      })
      .catch((e) => {
        console.info(e);
      });
  }

  async onChange(elem: presenza.item) {
    this.fetchData();
    this.manageVisible = false;
  }

  onClose() {
    this.manage = null;
    this.manageVisible = false;
  }
}
