import { Deferred } from '@/utils/Deferred';
import { ElForm } from 'element-ui/types/form';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { corsiService } from '../../services/corsiService';

@Component
export default class ManageLuogo extends Vue {
  @Prop({ required: true })
  value: corso.item;

  @Prop({ required: true })
  statoCorso: corso.statoCorso;

  @Prop({ default: false })
  disabled: boolean;

  public get corso(): corso.item {
    return this.value;
  }

  public set corso(v: corso.item) {
    this.$emit('input', v);
  }

  get formRules() {
    return {
      uri: [{
        required: !this.canFullEdit, validator: this.validateLink, trigger: 'change'
      }],
      nomeLuogo: [{ required: true, trigger: 'blur' }],
      indirizzo: [{ required: true, trigger: 'blur' }],
      cap: [{ required: true, validator: this.validateCap, trigger: 'blur' }],
      citta: [{ required: true, trigger: 'blur' }]
    };
  }

  get canFullEdit() {
    return true;
    // if (!this.corso) return false;
    // return this.statoCorso === 'inCompilazione'
    //   || this.statoCorso === 'proposto'
    //   || this.statoCorso === 'rifiutato'
    //   || this.statoCorso === 'pubblicato';
  }

  validateCap(rule, value, callback) {
    const regEx = /^[0-9]{5}$/;
    if (!value) {
      callback(new Error("Il CAP inserito non e' valido"));
      return;
    }

    if (!regEx.test(value)) {
      callback(new Error("Il CAP inserito non e' valido"));
      return;
    }

    callback();
  }

  async validateLink(rule, value: string, callback: Function) {
    if ((!value && !this.canFullEdit) || value) {
      const regEx = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
      if (!value || !regEx.test(value)) {
        callback(new Error("Link alla riunione o al corso di formazione online vuoto o non valido"));
        return;
      }

      if (value && value.toLowerCase().includes(`fpc.formazionegiornalisti.it`)) {
        const corsiUsedUri = (await corsiService.CheckUsedUri(value)).filter(f => f.id !== this.corso?.id);
        if (corsiUsedUri && corsiUsedUri.length > 0) {
          callback(new Error(`Link alla riunione o al corso di formazione già utilizzato ${corsiUsedUri.length > 1 ? 'dai corsi' : 'per il corso'}: \n\r${corsiUsedUri.map(m => m.titolo).join(', \n\r')}`));
          return;
        }
      }
    }

    callback();
  }

  switchType(value: boolean) {
    if (value) {
      this.corso.nomeLuogo = this.value.nomeLuogo;
      this.corso.citta = this.value.citta;
      this.corso.cap = this.value.cap;
      this.corso.indirizzo = this.value.indirizzo;
    } else {
      this.corso.uri = this.value.uri;
    }
  }

  async validate(): Promise<boolean> {
    var def = new Deferred<boolean>();
    (this.$refs.luogo as ElForm).validate(v => {
      def.resolve(v);
    });
    return def.promise;
  }
}
