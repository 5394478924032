import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class PresenzaService extends baseRestService {

    constructor() {
        super();
        this.baseUrl = () => settings.baseUrl;
    }

    async Count(corsoId: string, params?: presenza.queryParams): Promise<number> {
        return await this.Get<number>(`/api/corsi/${corsoId}/presenze/count`, params);
    }

    async List(corsoId: string, params?: presenza.queryParams): Promise<presenza.item[]> {
        return await this.Get<presenza.item[]>(`/api/corsi/${corsoId}/presenze`, params);
    }

    async Detail(corsoId: string, anagraficaGiornalistaId: string, sessioneId: string): Promise<presenza.item> {
        return await this.Get<presenza.item>(`/api/corsi/${corsoId}/presenze/${anagraficaGiornalistaId}/sessione/${sessioneId}`);
    }

    // async Set(corsoId: string, obj: presenza.item): Promise<presenza.item> {
    //     return await this.Post<presenza.item>(`/api/corsi/${corsoId}/presenze`, obj);
    // }

    async SetIngresso(corsoId: string, anagraficaGiornalistaId: string, sessioneId: string): Promise<presenza.item> {
        return await this.Put<presenza.item>(`/api/corsi/${corsoId}/presenze/${anagraficaGiornalistaId}/sessione/${sessioneId}/ingresso`, null);
    }

    async SetUscita(corsoId: string, anagraficaGiornalistaId: string, sessioneId: string): Promise<presenza.item> {
        return await this.Put<presenza.item>(`/api/corsi/${corsoId}/presenze/${anagraficaGiornalistaId}/sessione/${sessioneId}/uscita`, null);
    }

    async Remove(corsoId: string, anagraficaGiornalistaId: string, sessioneId: string): Promise<void> {
        return await this.Delete(`/api/corsi/${corsoId}/presenze/${anagraficaGiornalistaId}/sessione/${sessioneId}`);
    }
}

export const presenzaService = new PresenzaService();