import { Vue, Component, Prop } from 'vue-property-decorator'
import Draggable from 'vuedraggable'

@Component({
  name: 'DraggableBadge',
  components: {
    Draggable
  }
})
export default class extends Vue {
  @Prop({ default: 'header' })
  private headerText!: string

  @Prop({ default: () => [] })
  private list!: { id: any, label: string }[]

  @Prop({ default: () => null })
  private options!: object

  setData(dataTransfer) {
    dataTransfer.setData('Text', '')
  }
}