import userAutocomplete from '@/modules/users/components/userAutocomplete.vue';
import { userService } from '@/modules/users/services/userService';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { rilevatoriService } from '../services/rilevatoriService';

@Component({
  components: {
    userAutocomplete
  }
})
export default class ListaRilevatori extends Vue {
  @Prop()
  corso: corso.item;

  @Prop({ required: false, default: false })
  disabled: boolean;

  loading: boolean = false;

  rilevatoriRef: user.summary[] = [];
  showDialog: boolean = false;

  pagination: { pageSizes: number[]; total: number } = {
    pageSizes: [20, 40, 60],
    total: 0
  };

  query: any = {
    search: null,
  };

  get rilevatori() {
    if (this.query.search)
      return this.rilevatoriRef.filter(x => x.nomeCompleto && x.nomeCompleto.toLowerCase().includes(this.query.search.toLowerCase()));
    return this.rilevatoriRef;
  }

  async created() {
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    this.pagination.total = await rilevatoriService.Count(this.corso.id);
    if (this.pagination.total > 0) {
      const rilevatoriUserIds = (await rilevatoriService.List(this.corso.id)).map(x => x.userId);
      if (rilevatoriUserIds.length) {
        this.rilevatoriRef = await userService.getUsersByIds(rilevatoriUserIds);
      }
    } else {
      this.rilevatoriRef = [];
    }
    this.loading = false;
  }

  selectedUserId: string = null;
  saving: boolean = false;
  async onAdd() {
    if (!this.selectedUserId) {
      this.$message({
        message: 'Nessun rilevatore selezionato',
        type: 'error',
        duration: 5 * 1000
      });
      return;
    }

    if (this.rilevatoriRef.findIndex(x => x.id === this.selectedUserId) !== -1) {
      this.$message({
        message: "L'utente selezionato è già un rilevatore",
        type: "error"
      });
      return;
    }

    rilevatoriService.Set(this.corso.id, {
      corsoId: this.corso.id,
      userId: this.selectedUserId
    } as rilevatore.item).then(() => {
      this.fetchData();
      this.showDialog = false;
    }).catch(() => {
      this.$message({
        message: "Errore durante l'inserimento del rilevatore",
        type: 'error',
        showClose: true,
        duration: 5 * 1000,
      });
      this.showDialog = false;
    });
  }

  async handleDelete(user: user.item) {
    this.$msgbox({
      title: `Attenzione`,
      message: `Il rilevatore <strong>${user.nomeCompleto}</strong> verrà cancellato definitivamente. Continuare?`,
      showCancelButton: true,
      confirmButtonText: `Si`,
      cancelButtonText: `Annulla`,
      dangerouslyUseHTMLString: true,
      type: 'warning'
    }).then(() => {
      rilevatoriService.Remove(this.corso.id, user.id).then(() => {
        this.fetchData();
      }).catch(() => {
        this.$message({
          message: 'Errore durante la rimozione del rilevatore',
          type: 'error',
          showClose: true,
          duration: 5 * 1000,
        });
      });
    }).catch(err => {
      console.info(err);
    });
  }

  validate() {
    return this.pagination.total > 0
  }
}
