import { Form } from 'element-ui';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { moduliService } from '@/modules/moduli/service/moduliService';
import listaArgomenti from '@/modules/argomenti/components/listaArgomenti/listaArgomenti.vue';

@Component({
    components: {
        listaArgomenti
    }
})
export default class DialogInsertModulo extends Vue {
    @Prop({ required: true })
    corsoId: string;

    @Prop()
    moduloRef: modulo.item;

    
    loading = false;
    
    private rules = {
        nome: [{ required: true, trigger: 'blur' }],
    }
    
    modulo: modulo.item = null;
    created() {
        this.modulo = Object.assign({}, this.moduloRef);
        this.modulo.corsoId = this.corsoId;
        this.modulo.durataTimeSpan = null;
    }

    get durataInMinuti() {
        return (this.modulo?.ore || 0) * 60 + (this.modulo?.minuti || 0);
    }

    private async onSubmit() {
        (this.$refs.form as Form)?.validate(async v => {
            if (v) {
                if (this.durataInMinuti <= 0) {
                    this.$alert(`La durata in minuti del modulo ${this.modulo.nome} non può essere uguale a 0.`, 'Attenzione!', {
                        confirmButtonText: 'Ok',
                        type: "warning",
                        dangerouslyUseHTMLString: true
                    });
                    return;
                }
                this.loading = true;
                await moduliService.Set(this.corsoId, this.modulo).then((modulo) => {
                    this.$emit('change', modulo);
                    this.loading = false;
                }).catch(() => {
                    this.$message({
                        message: `Si è verificato un errore nell'inserimento del modulo <strong><i>${this.modulo.nome}</i></strong>.`,
                        showClose: true,
                        dangerouslyUseHTMLString: true,
                        type: 'error',
                        duration: 5 * 1000
                      });
                    this.loading = false;
                });
            }
        })
    }
}