import { Component, Prop, Vue } from 'vue-property-decorator'
import DraggableBadge from '@/components/DraggableBadge/index.vue'
import { relatoriService } from '@/modules/corsiRelatori/services/relatoriService'
import { relatoreSessioneService } from '../../services/relatoreSessioneService'

@Component({
  name: 'DraggableRelatori',
  components: {
    DraggableBadge
  }
})
export default class extends Vue {
  @Prop({ required: true })
  corsoId: string

  @Prop()
  sessioneId?: string

  @Prop({ default: 'relatori' })
  group: string


  relatoriCorsoAssegnabili: { id: any, label: string }[] = [];
  relatoriSessione: { id: any, label: string }[] = [];

  loading: boolean = false;
  async created() {
    this.loading = true;
    const relatori = (await relatoriService.List(this.corsoId)).map(m => { return { id: m.id, label: `${m.nomeCompleto}, ${m.professione}` }; });
    const relatoriSessione = !this.sessioneId ? [] : (await relatoreSessioneService.List(this.corsoId, { sessioneId: this.sessioneId })).map(m => m.relatoreId);
    this.relatoriCorsoAssegnabili = relatori.filter(f => !relatoriSessione.includes(f.id));
    this.relatoriSessione = relatori.filter(f => relatoriSessione.includes(f.id));
    this.handleRelatoriAssignedChange(this.relatoriSessione);
    this.loading = false;
  }

  handleRelatoriAssignedChange(values: { id: any, label: string }[]) {
    this.$emit('change', values.map(m => {
      return {
        corsoId: this.corsoId,
        sessioneId: this.sessioneId || null,
        relatoreId: m.id
      } as relatoreSessione.item
    }))
  }

  handleMoveToLeft() {
    this.relatoriSessione = this.relatoriSessione.concat(this.relatoriCorsoAssegnabili);
    this.relatoriCorsoAssegnabili = [];
    this.handleRelatoriAssignedChange(this.relatoriSessione);
  }

  handleMoveToRight() {
    this.relatoriCorsoAssegnabili = this.relatoriCorsoAssegnabili.concat(this.relatoriSessione);
    this.relatoriSessione = [];
    this.handleRelatoriAssignedChange(this.relatoriSessione);
  }
}