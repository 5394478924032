import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { enteService } from '@/modules/enti/service/enteService';
import { userService } from '@/modules/users/services/userService';

import { ElForm } from 'element-ui/types/form';

import referenteAutocomplete from '../referenteAutocomplete/referenteAutocomplete.vue';
import enteAutocomplete from '@/modules/enti/components/enteAutocomplete.vue';

@Component({
  name: "ManageReferente",
  components: {
    referenteAutocomplete,
    enteAutocomplete
  }
})
export default class ManageReferente extends Vue {
  @Prop({ required: true })
  value: corso.item;

  @Prop({ default: false })
  disabled: boolean;

  ente: ente.item = null;
  enteTipo: "azienda" | "cts" | "crog" | "cnog" | "etf" = 'azienda';

  public get corso(): corso.item {
    return this.value;
  }
  public set corso(v: corso.item) {
    this.$emit('input', v);
  }

  get formRules() {
    return {
      enteId: [{ required: true, trigger: 'blur' }],
      "referente.nome": [{ required: true, trigger: 'blur' }],
      "referente.cognome": [{ required: true, trigger: 'blur' }],
      "referente.email": [{ required: true, trigger: 'blur' }]
    };
  }

  get hasDisabled(): boolean {
    return this.disabled || (this.corso && !!this.corso.referenteId);
  }

  get utenzaEmpty(): corso.utenza {
    return {
      id: null,
      nome: null,
      cognome: null,
      nomeCompleto: null,
      email: null,
      phoneNumber: null,
      anagraficaEnteId: null,
      enteDescrizione: null,
      anagraficaGiornalistaId: null,
    } as corso.utenza;
  }

  init() {
    if (this.ente) {
      this.enteTipo = this.ente?.tipo || 'azienda';
      this.corso.referente.anagraficaEnteId = this.ente.id;
      this.corso.referente.enteDescrizione = this.ente.nome;
    }
  }

  async created() {
    await this.enteIdChanged(this.value?.enteId, null);
    await this.referenteIdChanged(this.value?.referenteId, null);
    this.init();
  }

  @Watch('corso.enteId')
  async enteIdChanged(n, o) {
    if (n && n !== o) {
      this.ente = await enteService.Detail(n);
      this.enteTipo = this.ente?.tipo || 'azienda';
      if (o) {
        this.corso.referenteId = null;
        this.corso.referente = Object.assign({}, this.utenzaEmpty);
      }

      if(!this.corso.referente) {
        this.corso.referente = Object.assign({}, this.utenzaEmpty);
      }
      this.corso.referente.enteDescrizione = this.ente.nome ?? this.ente.descrizione;
    } else {
      this.ente = null;
      this.corso.referenteId = null;
      this.corso.referente = Object.assign({}, this.utenzaEmpty);
    }
  }

  @Watch('corso.referenteId')
  async referenteIdChanged(n, o) {
    if (n && n !== o) {
      const user = await userService.Detail(n);
      this.corso.referente = {
        id: user.id,
        nome: user.nome,
        cognome: user.cognome,
        nomeCompleto: user.nomeCompleto,
        email: user.email,
        phoneNumber: user.phoneNumber,
        anagraficaEnteId: user.anagraficaEnteId,
        enteDescrizione: this.ente?.nome,
        anagraficaGiornalistaId: user.anagraficaGiornalistaId
      };
    } else {
      if(n !== o)
        this.corso.referente = Object.assign({}, this.utenzaEmpty);
    }
  }

  @Watch("corso.referente")
  manageChange() {
    this.$emit('input', this.corso);
  }

  validate() {
    let v: boolean = false;
    (this.$refs.referenteCorso as ElForm).validate((valid) => { v = valid });
    return v
  }
}
