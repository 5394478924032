import settings from '@/settings';
import { baseRestService } from '@/services/_base/baseRestService'

class GiornalistiCorsoService extends baseRestService {
  constructor() {
    super();
    this.baseUrl = () => settings.baseUrl;
  }

  async CountCorsiAperti(params?: giornalista.corso.queryParams): Promise<number> {
    return await this.Get<number>('/api/giornalisti/corsi/aperti/count', params)
  }

  async ListCorsiAperti(params?: giornalista.corso.queryParams): Promise<giornalista.corso.item[]> {
    return await this.Get('/api/giornalisti/corsi/aperti', params)
  }

  async CountCorsiProroga(params?: giornalista.corso.queryParams): Promise<number> {
    return await this.Get<number>('/api/giornalisti/corsi/proroga/count', params)
  }

  async ListCorsiProroga(params?: giornalista.corso.queryParams): Promise<giornalista.corso.item[]> {
    return await this.Get('/api/giornalisti/corsi/proroga', params)
  }

  async CountIMieiCorsi(anagraficaGiornalistaId: string, params?: giornalista.corso.queryParams): Promise<number> {
    return await this.Get<number>(`/api/giornalisti/corsi/iscrizioni/${anagraficaGiornalistaId}/count`, params)
  }

  async ListIMieiCorsi(anagraficaGiornalistaId: string, params?: giornalista.corso.queryParams): Promise<giornalista.corso.item[]> {
    return await this.Get(`/api/giornalisti/corsi/iscrizioni/${anagraficaGiornalistaId}`, params)
  }

  async DetailCorso(id: string): Promise<giornalista.corso.item> {
    return await this.Get(`/api/giornalisti/corsi/${id}`)
  } 

  async CountCorsiInAnagrafica(anagraficaGiornalistaId: string, params?: giornalista.corso.queryParams): Promise<number> {
    return await this.Get<number>(`/api/giornalisti/corsi/count/corsiInAnagrafica/${anagraficaGiornalistaId}`, params)
  }

  async ListCorsiInAnagrafica(anagraficaGiornalistaId: string, params?: giornalista.corso.queryParams): Promise<giornalista.corso.item[]> {
    return await this.Get(`/api/giornalisti/corsi/corsiInAnagrafica/${anagraficaGiornalistaId}`, params)
  }

  async NotificaModLuogoDataReferente(notifica:giornalista.corso.notificaModLuogoDataReferente){
    return await this.Post(`/api/giornalisti/corsi/notifica-mod-luogo-data-referente`, notifica)
  }
}

export const giornalistiCorsoService = new GiornalistiCorsoService()
