import { store } from "@/store";

export type statoCorso = 'inCompilazione' | 'proposto' | 'approvato' | 'rifiutato' | 'pubblicato' | 'inErogazione' | 'sospeso' | 'annullato' | 'obsoleto' | 'chiuso';

export function statiDisponibili(corso: corso.item) {
    if (!corso) return [];
    let stati: statoCorso[] = [];
    if ((corso.stato === 'inCompilazione' || corso.stato === 'proposto' || corso.stato === 'rifiutato') && $can('CORSO.canSetStato.inCompilazione')) {
        stati.push('inCompilazione');
    }

    if ((corso.stato === 'inCompilazione' || corso.stato === 'proposto' || corso.stato === 'rifiutato') && $can('CORSO.canSetStato.proposto')) {
        stati.push('proposto');
    }

    if ((corso.stato === 'sospeso') && $can('CORSO.canSetStato.pubblicato')) {
        stati.push('pubblicato');
    }

    if (corso.stato === 'proposto' && $can('CORSO.canSetStato.pubblicato')) {
        stati.push('pubblicato');
    }

    if (corso.stato === 'proposto' && $can('CORSO.canSetStato.rifiutato')) {
        stati.push('rifiutato');
    }

    if (corso.stato === 'pubblicato' && $can('CORSO.canSetStato.inErogazione')) {
        stati.push(corso.stato);
        stati.push('inErogazione')
    }

    if ((corso.stato === 'pubblicato' || corso.stato === 'inErogazione') && $can('CORSO.canSetStato.sospeso')) {
        stati.push(corso.stato)
        stati.push('sospeso');
    }

    if ((corso.stato === 'pubblicato' || corso.stato === 'sospeso') && $can('CORSO.canSetStato.annullato')) {
        stati.push('annullato');
    }

    if (corso.stato === 'proposto' || corso.stato === 'sospeso' || corso.stato === 'annullato' || corso.stato === 'chiuso' && $can('CORSO.canSetStato.obsoleto')) {
        stati.push('obsoleto');
    }

    if (corso.stato === 'inErogazione' && $can('CORSO.canSetStato.chiuso')) {
        stati.push('chiuso');
    }
    return [...new Set(stati)];
}

export function abilitatoAllaModificaCorsoDaStato(corso: corso.item, stato: corso.statoCorso) {
    const stati = statiDisponibili(corso);
    return $can('CORSO.canEdit') && (stati && stati.length > 0 && (corso?.stato === stato));
}

export function abilitatoAllaModificaCorso(corso: corso.item) {
    const stati = statiDisponibili(corso);
    return $can('CORSO.canEdit') && (stati && stati.length > 0) && (corso.stato === 'inCompilazione' || corso.stato === 'rifiutato' || corso.stato === 'proposto');
}

// export function abilitatoAllaModificaCorso(corso: corso.item) {
//     if (!corso) return false;
//     const stati = statiDisponibili(corso);
//     return $can('CORSO.canEdit')
//         && (stati && stati.length > 0)
//         && (['inErogazione', 'sospeso', 'annullato', 'obsoleto', 'chiuso'].indexOf(corso.stato) < 0);
// }

function $can(key: string) {
    return store.state.login.claims.indexOf(key) >= 0;
}