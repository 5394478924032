import { corsiService } from "@/modules/corsi/services/corsiService";
import iscrittoAutocomplete from "@/modules/giornalisti/components/iscrittoAutocomplete/iscrittoAutocomplete.vue";
import sessioneAutocomplete from "@/modules/sessioni/components/sessione-autocomplete.vue";
import { ElForm } from "element-ui/types/form";
import { Component, Prop, Vue } from "vue-property-decorator";
import { presenzaService } from "../../services/presenzaService";

@Component({
    components: {
        sessioneAutocomplete,
        iscrittoAutocomplete
    }
})
export default class ManagePresenza extends Vue {
    @Prop({ required: true })
    corsoId: string;

    @Prop({ required: false })
    sessioneId: string;

    @Prop({ required: false })
    iscrittoId: string;

    corso: corso.item = null;

    get validationRules() {
        return {
            iscrittoId: [{ required: true, trigger: 'change' }],
            sessioneId: [{ required: true, trigger: 'change' }],
        }
    }

    manage: { iscrittoId: string, sessioneId: string } = {
        iscrittoId: null,
        sessioneId: null,
    };

    async created() {
        if (this.iscrittoId) this.manage.iscrittoId = this.iscrittoId;
        if (this.sessioneId) this.manage.sessioneId = this.sessioneId;

        this.corso = await corsiService.Detail(this.corsoId);
    }

    loading: boolean = false;
    async ingresso() {
        (this.$refs.form as ElForm)?.validate(async valid => {
            if (valid) {
                if (this.corso.stato !== "inErogazione" && this.corso.stato !== "chiuso") {
                    this.$message({
                        message: `Non si possono inserire le presenze al corso con stato diverso da <strong>${this.$t(`corso.stati.inErogazione`)}</strong> o <strong>${this.$t(`corso.stati.chiuso`)}</strong>`,
                        type: 'error',
                        dangerouslyUseHTMLString: true,
                        center: true,
                        showClose: true,
                        duration: 5 * 1000
                    });
                    return;
                }

                this.loading = true;
                presenzaService.SetIngresso(this.corsoId, this.manage.iscrittoId, this.manage.sessioneId).then((result) => {
                    this.$message({
                        message: "Registrazione dell'ingresso alla sessione avvenuto con successo",
                        type: "success"
                    });
                    this.$emit("change", result);
                }).catch(() => {
                    this.$message({
                        message: "Si è verificato un problema nella registrazione della presenza alla sessione del corso",
                        type: "error"
                    });
                });
            }
        })
    }
}