import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class RilevatoriService extends baseRestService {
    constructor() {
        super();
        this.baseUrl = () => settings.baseUrl;
    }

    async Count(corsoId: string): Promise<number> {
        return await this.Get(`/api/corsi/${corsoId}/rilevatori/count`);
    }

    async List(corsoId: string): Promise<rilevatore.item[]> {
        return await this.Get(`/api/corsi/${corsoId}/rilevatori`);
    }

    async Detail(corsoId: string, id: string): Promise<rilevatore.item> {
        return await this.Get(`/api/corsi/${corsoId}/rilevatori/${id}`);
    }

    async Set(corsoId: string, item: rilevatore.item): Promise<rilevatore.item> {
        return await this.Post(`/api/corsi/${corsoId}/rilevatori`, item);
    }

    async Remove(corsoId: string, id: string) {
        return await this.Delete(`/api/corsi/${corsoId}/rilevatori/${id}`);
    }
}

export const rilevatoriService = new RilevatoriService();