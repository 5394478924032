import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class RelatoreSessioneService extends baseRestService {

    constructor() {
        super();
        this.baseUrl = () => settings.baseUrl;
    }

    async Count(corsoId: string, params?: relatoreSessione.queryParams): Promise<number> {
        return await this.Get<number>(`/api/corsi/${corsoId}/sessioni/relatori/count`, params);
    }

    async List(corsoId: string, params?: relatoreSessione.queryParams): Promise<relatoreSessione.item[]> {
        return await this.Get<relatoreSessione.item[]>(`/api/corsi/${corsoId}/sessioni/relatori`, params);
    }

    async Detail(corsoId: string, relatoreId: string, sessioneId: string): Promise<relatoreSessione.item> {
        return await this.Get<relatoreSessione.item>(`/api/corsi/${corsoId}/sessioni/relatori/${relatoreId}/sessione/${sessioneId}`);
    }

    async Set(corsoId: string, obj: relatoreSessione.item): Promise<relatoreSessione.item> {
        return await this.Post<relatoreSessione.item>(`/api/corsi/${corsoId}/sessioni/relatori`, obj);
    }

    async Remove(corsoId: string, relatoreId: string, sessioneId: string): Promise<void> {
        return await this.Delete(`/api/corsi/${corsoId}/sessioni/relatori/${relatoreId}/sessione/${sessioneId}`);
    }
}

export const relatoreSessioneService = new RelatoreSessioneService();