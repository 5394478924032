import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { corsiService } from '@/modules/corsi/services/corsiService';
import { categorieService } from '@/modules/corsiCategorie/services/categorieService';
import { authService } from '@/modules/account/services/authService';
import { periodoFormativoService } from '@/modules/offertaFormativa/services/periodoFormativoService';
import { pofService } from '@/modules/offertaFormativa/services/pianoOffertaFormativaService';
import { enteService } from '@/modules/enti/service/enteService';
import { iscrittoService } from "@/modules/giornalisti/services/iscrittoService";
import { moduliService } from '@/modules/moduli/service/moduliService';
import { sessioneService } from '@/modules/sessioni/services/sessioneService';
import { giornalistiCorsoService } from '@/modules/giornalisti/services/giornalistiCorsoService';

import { loginStore } from '@/store/modules/login.store';

import textHtmlEditor from '@/components/ToastHTMLEditor/TextHtmlEditor.vue';
import ContentEditor from '@/components/ContentEditor/contentEditor.vue';
import categoriaAutocomplete from '@/modules/corsiCategorie/components/categoriaAutocomplete.vue';
import SelectPeriodoFormativo from '@/modules/offertaFormativa/components/selectPeriodoFormativo.vue';
//TABS
import ManageLuogo from '../components/manageLuogo/manageLuogo.vue';
import ManageReferente from '../components/manageReferente/manageReferente.vue';
import Moduli from '@/modules/moduli/components/listaModuli/listaModuli.vue';
import Sessioni from '@/modules/sessioni/views/sessioni.vue';
import Relatori from '@/modules/corsiRelatori/components/listaRelatori.vue';
import Rilevatori from '@/modules/corsiRilevatori/components/listaRilevatori.vue';
import iscritti from '@/modules/giornalisti/views/iscritti.vue';
import presenze from '@/modules/giornalisti/views/presenze.vue';
import TimelineStoricoStati from '../components/timeline-storico-stati/timeline-storico-stati.vue';

import settings from '@/settings';
import { abilitatoAllaModificaCorso, abilitatoAllaModificaCorsoDaStato, statiDisponibili, statoCorso } from './utils';
import { Deferred } from '@/utils/Deferred';
import { formatTime } from '@/filters';
import { ElForm } from 'element-ui/types/form';

@Component({
  components: {
    textHtmlEditor,
    ContentEditor,
    categoriaAutocomplete,
    SelectPeriodoFormativo,
    ManageLuogo,
    ManageReferente,
    Moduli,
    Relatori,
    Rilevatori,
    Sessioni,
    iscritti,
    presenze,
    TimelineStoricoStati
  }
})
export default class EditCorso extends Vue {
  @Prop({ required: true })
  id: string;

  periodiFormativi: periodoFormativo.item[] = [];
  periodoFormativo: periodoFormativo.item = null;
  showSelectPeriodiFormativi: boolean = false;

  pof: pianoOffertaFormativa.item = null;

  ente: ente.item = null;

  moduli: modulo.item[] = [];

  sessioni: sessione.item[] = [];

  stato: statoCorso = null;

  webinarMaxOre = settings.appSetting.corsi.maxDurata.webinar.quantitaOre;
  creditiConsigliati: number = null;

  activeTab: string = 'generale';

  corsoRef: corso.item = null;
  corso: corso.item = null;
  loading: boolean = false;

  user: user.summary = null;

  quantitaIscritti: number = 0;

  notificaModLuogoDataRelatori: giornalista.corso.notificaModLuogoDataReferente

  async created() {
    this.user = loginStore.state.userInfo || await authService.WhoAmI();
    if (!this.user || (this.user && !this.user.anagraficaEnteId)) {
      this.$alert(`All'utente <strong>${this.user?.nomeCompleto}</strong> non è associato nessun Organo o Ente di cui questo è referente.<br>Contattare l'amministrazione per completare l'associazione dell'utenza con l'ente.`, 'Attenzione!', {
        confirmButtonText: 'Ok',
        type: "warning",
        dangerouslyUseHTMLString: true
      });
      this.$router.push('/corsi');
      return;
    }
    await this.fetchData();
    this.quantitaIscritti = await iscrittoService.Count(this.corsoRef.id, { cancellato: false, ammesso: true })
  }

  async fetchData() {
    this.loading = true;
    this.corsoRef = await corsiService.Detail(this.id);
    if (this.stati.includes(this.corsoRef.stato)) {
      this.stato = this.corsoRef.stato;
    }

    this.creditiConsigliati = await corsiService.CalcolaCreditiSuggeriti(this.corsoRef.id);
    this.moduli = await moduliService.List(this.corsoRef.id);
    this.sessioni = await sessioneService.List(this.corsoRef.id);

    if (this.corsoRef.data) {
      const date: Date = typeof (this.corsoRef.data) === 'string' ? new Date(this.corsoRef.data) : this.corsoRef.data;
      const l = await periodoFormativoService.GetByDate(date);
      this.periodiFormativi = l.filter(f => (f.stato === 'prorogato' || f.stato === 'corrente') && (!f.dataFineProroga || f.dataFineProroga.setHours(0, 0, 0, 0) >= date.setHours(0, 0, 0, 0)))
    }

    if (this.corsoRef?.periodoFormativoId || this.pof?.periodoFormativoId) {
      this.periodoFormativo = await periodoFormativoService.Detail(this.corsoRef.periodoFormativoId || this.pof.periodoFormativoId);
      if (!this.corsoRef.continuativo)
        this.pof = await pofService.GetByDate(this.corsoRef.data)
    }

    if (this.corsoRef.enteId) {
      this.ente = await enteService.Detail(this.corsoRef.enteId);
    }
    this.corso = Object.assign({}, this.corsoRef);

    this.loading = false;

    this.$nextTick(() => {
      if (!this.periodoFormativo && this.corsoRef?.data && this.periodiFormativi.length > 0) {
        if (this.periodiFormativi.length === 1) {
          this.periodoFormativo = this.periodiFormativi[0];
          this.computeCorsoDataPeriodoFormativo(this.corso.data);
          return
        } else {
          this.showSelectPeriodiFormativi = true;
          return;
        }
      }
    })
  }

  async computedCreditiConsigliati(moduli: modulo.item[]) {
    this.moduli = moduli;
    this.creditiConsigliati = await corsiService.CalcolaCreditiSuggeriti(this.corsoRef.id);
  }

  async undo() {
    await this.fetchData();
  }

  get stati() {
    return statiDisponibili(this.corsoRef);
  }

  get canEdit() {
    return abilitatoAllaModificaCorso(this.corsoRef) || abilitatoAllaModificaCorsoDaStato(this.corsoRef, "pubblicato");
  }

  get canEditDataCorso(): boolean {
    return this.corsoRef.stato === 'inCompilazione' || this.corsoRef.stato === 'proposto' || this.corsoRef.stato === 'pubblicato'
  }

  get canEditDataIscrizione(): boolean {
    return this.corsoRef.stato === 'inCompilazione' || this.corsoRef.stato === 'proposto' || this.corsoRef.stato === 'pubblicato'
  }

  get canEditNumeroPartecipanti(): boolean {
    return this.corsoRef.stato === 'inCompilazione' || this.corsoRef.stato === 'proposto' || this.corsoRef.stato === 'pubblicato'
  }

  get canEditCosto(): boolean {
    return this.corsoRef.stato === 'inCompilazione' || this.corsoRef.stato === 'proposto'
  }

  get canEditCrediti(): boolean {
    return this.corsoRef.stato === 'inCompilazione' || this.corsoRef.stato === 'proposto'
  }

  get canEditIfCorsoDeontologico(): boolean {
    return this.corsoRef.stato === 'inCompilazione' || this.corsoRef.stato === 'proposto' || this.corsoRef.stato === 'pubblicato'
  }

  get canEditProgramma(): boolean {
    return this.corsoRef.stato === 'inCompilazione' || this.corsoRef.stato === 'proposto' || this.corsoRef.stato === 'pubblicato'
  }

  get canEditLuogo(): boolean {
    return this.corsoRef.stato === 'inCompilazione' || this.corsoRef.stato === 'proposto' || this.corsoRef.stato === 'pubblicato'
  }

  get canEditReferente(): boolean {
    return this.corsoRef.stato === 'inCompilazione' || this.corsoRef.stato === 'proposto' || this.corsoRef.stato === 'pubblicato'
  }

  get canEditModuli(): boolean {
    return this.corsoRef.stato === 'inCompilazione' || this.corsoRef.stato === 'proposto' || this.corsoRef.stato === 'pubblicato'
  }

  get canEditRelatori(): boolean {
    return this.corsoRef.stato === 'inCompilazione' || this.corsoRef.stato === 'proposto' || this.corsoRef.stato === 'pubblicato' || this.corsoRef.stato === 'inErogazione' || this.corsoRef.stato === 'sospeso'
  }

  get canEditSessioni(): boolean {
    return this.corsoRef.stato === 'inCompilazione' || this.corsoRef.stato === 'proposto' || this.corsoRef.stato === 'pubblicato' || this.corsoRef.stato === 'inErogazione' || this.corsoRef.stato === 'sospeso'
  }

  get canEditRilevatori(): boolean {
    return this.corsoRef.stato === 'inCompilazione' || this.corsoRef.stato === 'proposto' || this.corsoRef.stato === 'pubblicato' || this.corsoRef.stato === 'inErogazione' || this.corsoRef.stato === 'sospeso'
  }

  get canEditIscritti(): boolean {
    return this.corsoRef.stato === 'inErogazione'
  }

  get showTabIscritti(): boolean {
    return this.corsoRef.stato !== 'inCompilazione' && this.corsoRef.stato !== 'proposto' && this.corsoRef.stato !== 'rifiutato'
  }

  get canEditPresenze(): boolean {
    return this.corsoRef.stato === 'inErogazione'
  }

  get showTabPresenze(): boolean {
    return this.corsoRef.stato !== 'inCompilazione' && this.corsoRef.stato !== 'proposto' && this.corsoRef.stato !== 'rifiutato'
  }

  get limitMinNumeroMassimoPartecipanti() {
    if (this.corso.continuativo) return 0;
    return Math.max(this.corso.numeroMinimoPartecipanti, this.quantitaIscritti);
  }

  get formRules() {
    if (!this.stato || this.stato === 'inCompilazione') {
      return {
        titolo: [{ required: true, trigger: 'blur', message: `Il campo "Titolo" è obbligatorio` }],
        data: [{ required: true, trigger: 'change', message: `Il campo "Data" è obbligatorio` },
        { validator: async (rule, value, callback) => this.checkPeriodoFormativo(rule, value, callback), trigger: 'blur' },
        { validator: async (rule, value, callback) => this.checkPof(rule, value, callback), trigger: 'blur' }]
      };
    }
    let numeroMassimoPartecipanti: any = [
      { type: 'number', required: !this.corso.continuativo, trigger: 'blur' },
    ];

    if (this.canEditNumeroPartecipanti) {
      numeroMassimoPartecipanti.push({
        type: 'number',
        min: this.limitMinNumeroMassimoPartecipanti,
        message: `Il 'Numero massimo di partecipanti' deve essere maggiore 
          ${this.limitMinNumeroMassimoPartecipanti === this.corso.numeroMinimoPartecipanti
            ? "del 'Numero minimo di partecipanti' impostato a"
            : 'del numero di iscritti al corso di'} 
          ${this.limitMinNumeroMassimoPartecipanti}`,
        trigger: 'blur'
      })
    }
    return {
      titolo: [{ required: true, trigger: 'blur' }],
      data: [{ required: true, trigger: 'change' },
      { validator: async (rule, value, callback) => this.checkPeriodoFormativo(rule, value, callback), trigger: 'blur' },
      { validator: async (rule, value, callback) => this.checkPof(rule, value, callback), trigger: 'blur' }],
      dataScadenza: [{ required: true, trigger: 'change' }],
      dataInizioIscrizione: [{ required: true, trigger: 'change' }],
      dataFineIscrizione: [{ required: true, trigger: 'change' }],
      numeroMinimoPartecipanti: [{ type: 'number', required: !this.corso.continuativo, trigger: 'blur' }],
      numeroMassimoPartecipanti: numeroMassimoPartecipanti,
      costo: [{ required: true, trigger: 'change' }],
      creditiDisponibili: [{ required: true, trigger: 'change' }],
    };
  }

  get dataScadenzaPickerOptions() {
    const _self = this;
    return {
      disabledDate(time: Date) {
        if (!_self.corso.data) return true;

        const startPeriodo: Date = new Date(formatTime(_self.corso.data, 'YYYY-MM-DD'));
        let endPeriodo: Date = null;

        if (_self.corso.continuativo) {
          if ((_self.ente.tipo === 'cts' || _self.ente.tipo === 'cnog' || _self.ente.tipo === 'crog') && _self.periodoFormativo) {
            endPeriodo = new Date(_self.periodoFormativo.dataFine, 11, 31);
            if (_self.periodoFormativo.stato === 'prorogato' && _self.periodoFormativo.dataFineProroga) {
              endPeriodo = _self.periodoFormativo.dataFineProroga;
            }
            if (time < startPeriodo || time > endPeriodo) return true;
            return false;
          } else {
            // ---- data scadenza limitata all'anno di inizio ----
            endPeriodo = new Date(startPeriodo.getFullYear(), 11, 31);
            if (startPeriodo.getFullYear() === _self.periodoFormativo.dataFine && _self.periodoFormativo.stato === 'prorogato' && _self.periodoFormativo.dataFineProroga) {
              endPeriodo = _self.periodoFormativo.dataFineProroga;
            }
            if (time < startPeriodo || time > endPeriodo) return true;
            return false;
          }
        } else {
          // ---- data scadenza limitata all'anno di inizio ----
          endPeriodo = new Date(startPeriodo.getFullYear(), 11, 31);
          if (startPeriodo.getFullYear() === _self.periodoFormativo.dataFine ||
            _self.corso.inProroga && _self.periodoFormativo.dataFineProroga) {
            endPeriodo = _self.periodoFormativo.dataFineProroga;
          }
          if (time < new Date(startPeriodo.toDateString()) || time > new Date(endPeriodo.toDateString())) {
            return true;
          }
          return false;
        }
      }
    };
  }

  get dataCorsoPickerOptions() {
    const _self = this;
    return {
      disabledDate(data: Date) {
        if (_self.corsoRef.stato.toString() === 'inCompilazione' || _self.corsoRef.stato.toString() === 'proposto') return false;
        return ((data.getTime() < new Date(_self.corsoRef.data).getTime()) || (data.getTime() < Date.now()));
      }
    }
  }

  get dataInizioIscrizionePickerOptions() {
    const _self = this;
    const dataCorso: Date = new Date(formatTime(_self.corso.data, 'YYYY-MM-DD'));

    return {
      disabledDate(time: Date) {
        let startPeriodo: Date = null;
        let endPeriodo: Date = null;

        if (!_self.corso.data) return true;

        if (_self.corso.continuativo) {
          if ((_self.ente.tipo === 'cts' || _self.ente.tipo === 'cnog' || _self.ente.tipo === 'crog') && _self.periodoFormativo) {
            startPeriodo = new Date(_self.periodoFormativo.dataInizio, 0, 1);
            endPeriodo = new Date(_self.periodoFormativo.dataFine, 11, 31);
            if (time < startPeriodo || time > endPeriodo) return true;
            return false;
          } else {
            startPeriodo = new Date(dataCorso.getFullYear(), 0, 1);
            endPeriodo = new Date(dataCorso.getFullYear(), 11, 31);
            if (time < startPeriodo || time > endPeriodo) return true;
            return false;
          }
        } else {
          endPeriodo = new Date(dataCorso.getTime());
          endPeriodo.setDate(dataCorso.getDate() - 1);
          if (time > endPeriodo) return true;
          return false;
        }
      }
    };
  }

  get dataFineIscrizionePickerOptions() {
    const _self = this;
    return {
      disabledDate(time: Date) {
        if (!_self.corso.dataInizioIscrizione || !_self.corso.data) return true;

        const startPeriodo: Date = _self.corso.dataInizioIscrizione;
        const dataCorso: Date = new Date(formatTime(_self.corso.data, 'YYYY-MM-DD'));

        let endPeriodo: Date = null;

        if (_self.corso.continuativo) {
          if ((_self.ente.tipo === 'cts' || _self.ente.tipo === 'cnog' || _self.ente.tipo === 'crog') && _self.periodoFormativo) {
            endPeriodo = new Date(_self.periodoFormativo.dataFine, 11, 31);
            if (_self.periodoFormativo.stato === 'prorogato') {
              endPeriodo = new Date(formatTime(_self.periodoFormativo.dataFineProroga, 'YYYY-MM-DD'));
            }
            if (time < startPeriodo || time > endPeriodo) return true;
            return false;
          } else {
            endPeriodo = new Date(dataCorso.getFullYear(), 11, 31);
            if (_self.periodoFormativo.stato === 'prorogato') {
              endPeriodo = new Date(formatTime(_self.periodoFormativo.dataFineProroga, 'YYYY-MM-DD'));
            }
            if (time < startPeriodo || time > endPeriodo) return true;
            return false;
          }
        } else {
          endPeriodo = new Date(dataCorso.getTime());
          endPeriodo.setDate(dataCorso.getDate() - 1);
          if (time < startPeriodo || time > endPeriodo) return true;
          return false;
        }
      }
    };
  }

  get durataTotaleInMinutiModuli(): number {
    let minuti = 0;
    if (this.moduli && this.moduli.length > 0) {
      for (const modulo of this.moduli) {
        minuti += modulo.minuti + (modulo.ore * 60);
      }
    }
    return minuti;
  }

  get durataTotaleInMinutiSessioni(): number {
    if (this.sessioni.length <= 0) return null;
    return this.sessioni.map(m => m.durata).reduce((a, c) => a += c);
  }

  @Watch('corso.data')
  async dataChanged(n, o) {
    if (n && n !== o && o !== undefined) {
      this.periodoFormativo = null;
      await this.computeCorsoData(this.corso.data);
    } else {
      if (!n && o) {
        this.corso.dataInizioIscrizione = null;
        this.corso.dataFineIscrizione = null;
        this.corso.pofId = null;
        this.corso.periodoFormativoId = null;
        this.corso.inProroga = false;
      }
    }
  }

  @Watch('corso.continuativo')
  async continuativoChanged(n, o) {
    if (n !== undefined && o !== undefined && n !== o) {
      await this.computeCorsoData(this.corso.data);
    }
  }

  @Watch('corso.gratuito')
  async gratuitoChanged(n, o) {
    if (n) {
      this.corso.costo = 0;
    } else {
      this.corso.costo = this.corsoRef.costo;
    }
  }

  @Watch('corso.enteId')
  async enteChanged(n, o) {
    if (n && n !== o) {
      this.ente = await enteService.Detail(n);
    }
  }

  async computeCorsoData(n: Date) {
    if (n) {
      this.corso.dataInizioIscrizione = new Date(new Date(n).setDate(new Date(n).getDate() - 14));
      this.corso.dataFineIscrizione = new Date(new Date(n).setDate(new Date(n).getDate() - 1));
      this.corso.dataScadenza = new Date(new Date(n).setDate(new Date(n).getDate()));
      this.corso.periodoFormativoId = null;
      this.corso.inProroga = false;
      this.corso.pofId = null;

      if (this.periodoFormativo) {
        this.computeCorsoDataPeriodoFormativo(n);
        return;
      }

      const date: Date = typeof (n) === 'string' ? new Date(n) : n;
      const l = await periodoFormativoService.GetByDate(date);
      this.periodiFormativi = l.filter(f => (f.stato === 'prorogato' || f.stato === 'corrente') && (!f.dataFineProroga || f.dataFineProroga.setHours(0, 0, 0, 0) >= date.setHours(0, 0, 0, 0)))

      if (!this.periodiFormativi || this.periodiFormativi.length <= 0) {
        this.corso.periodoFormativoId = null;
        this.corso.pofId = null;
        this.periodoFormativo = null;
        this.pof = null;
        return;
      }

      if (this.periodiFormativi.length == 1) {
        this.periodoFormativo = this.periodiFormativi[0];
        this.computeCorsoDataPeriodoFormativo(n);
        return;
      }

      this.showSelectPeriodiFormativi = true;
    }
  }

  onConfirmSelectPeriodoFormativo(periodoFormativo: periodoFormativo.item) {
    this.periodoFormativo = periodoFormativo;
    this.computeCorsoDataPeriodoFormativo(this.corso.data);
  }

  async computeCorsoDataPeriodoFormativo(n: Date) {
    const date: Date = typeof (n) === 'string' ? new Date(n) : n;

    if (!this.periodoFormativo)
      return;

    this.corso.periodoFormativoId = this.periodoFormativo.id;
    if (!this.corso.continuativo || !this.ente) {
      this.pof = await pofService.GetByDate(new Date(date));
      if (new Date(this.corso.dataScadenza).getTime() <= new Date(date).getTime()) {
        this.corso.dataScadenza = new Date(date);
      }
      this.corso.pofId = this.pof?.id;
    } else {
      if (!this.ente) {
        this.corso.dataScadenza = null;
        this.corso.dataInizioIscrizione = null;
        this.corso.dataFineIscrizione = null;
      } else {
        if (this.periodoFormativo) {
          if (this.ente.tipo != `etf` && this.ente.tipo != `azienda`) {
            this.corso.dataInizioIscrizione = new Date(this.periodoFormativo.dataInizio, 0, 1);
            this.corso.dataFineIscrizione = this.periodoFormativo.stato === 'prorogato' ? new Date(formatTime(this.periodoFormativo.dataFineProroga, 'YYYY-MM-DD')) : new Date(this.periodoFormativo.dataFine, 11, 31, 23, 59, 59);
            this.corso.dataScadenza = this.periodoFormativo.stato === 'prorogato' ? new Date(formatTime(this.periodoFormativo.dataFineProroga, 'YYYY-MM-DD')) : new Date(this.periodoFormativo.dataFine, 11, 31, 23, 59, 59);
          } else {
            this.corso.dataInizioIscrizione = new Date(date.getFullYear(), 0, 1);
            this.corso.dataFineIscrizione = this.periodoFormativo.stato === 'prorogato' ? new Date(formatTime(this.periodoFormativo.dataFineProroga, 'YYYY-MM-DD')) : new Date(date.getFullYear(), 11, 31, 23, 59, 59);
            this.corso.dataScadenza = this.periodoFormativo.stato === 'prorogato' ? new Date(formatTime(this.periodoFormativo.dataFineProroga, 'YYYY-MM-DD')) : new Date(date.getFullYear(), 11, 31, 23, 59, 59);
          }
        }
      }
    }
    this.corso.inProroga = this.periodiFormativi.length > 1 && this.periodoFormativo.stato === "prorogato";
  }


  async checkPof(rule, value, callback) {
    // await this.computeCorsoData(this.corso.data);
    if (!value) {
      callback(new Error('Seleziona una data'));
      return;
    }

    if (!this.corso.continuativo && !this.corso.pofId && this.stato !== 'inCompilazione') {
      this.corso.pofId = (await pofService.GetByDate(this.corso.data))?.id
      if (!this.corso.pofId) {
        callback(new Error('Piano Offerta Formativa richiesto inesistente per la data selezionata'));
        return;
      }
      return;
    }

    callback();
  }

  async checkPeriodoFormativo(rule, value, callback) {
    // await this.computeCorsoData(this.corso.data);
    if (!value) {
      callback(new Error('Seleziona una data'));
      return;
    }

    //############################################################# PUNTO CRITICO #############################################################
    if ((!this.corso.periodoFormativoId || this.corso.periodoFormativoId === '00000000-0000-0000-0000-000000000000') && this.stato !== 'inCompilazione') {
      if (!this.periodoFormativo) {
        callback(new Error('Periodo Formativo richiesto inesistente per la data selezionata'));
      } else {
        this.corso.periodoFormativoId = this.periodoFormativo.id
        callback();
      }

      // var date = typeof (this.corso.data) === 'string' ? new Date(this.corso.data) : this.corso.data;
      // const periodoFormativo = (await periodoFormativoService.GetByDate(date));
      // if (periodoFormativo && (periodoFormativo.stato === 'pubblicato' || periodoFormativo.stato === 'corrente')) {
      //   this.corso.periodoFormativoId = this.periodoFormativo.id;
      //   callback();
      // } else {
      //  callback(new Error('Periodo Formativo richiesto inesistente per la data selezionata'));
      // }
    } else {
      callback();
    }
  }

  async duplicate() {
    this.saving = true;
    corsiService
      .Duplicate(this.id)
      .then(result => {
        this.$router.push({ name: 'modificaCorso', params: { id: result.id } });
        this.saving = false;
      })
      .catch(() => {
        this.$message({
          message: `Errore durante la duplicazione del corso <strong><i>${this.corso.titolo}</i></strong>`,
          dangerouslyUseHTMLString: true,
          type: 'error',
          duration: 5 * 1000
        });
        this.saving = false;
      });
  }

  handleAddCategoria() {
    this.$prompt(`Crea una nuova categoria da associare al proprio corso`, `Aggiungi una nuova categoria`, {
      confirmButtonText: `Salva`,
      cancelButtonText: 'Annulla',
      inputPattern: /^\s*\S.*$/,
      inputErrorMessage: `E' obbligatorio inserire il nome della categoria`
    })
      .then(({ value }: any) => {
        categorieService
          .Set({ id: null, nome: value })
          .then(result => {
            this.corso.categoriaId = result.id;
            this.$message({
              message: `La categoria <strong>${result.nome}</strong> è stata aggiunta con successo ed è stata associata al corso`,
              dangerouslyUseHTMLString: true,
              type: 'success',
              duration: 5000
            });
          })
          .catch(error => {
            this.$message({
              message: `Si è verificato un errore nell'inserimento della categoria <strong>${value}</strong>`,
              showClose: true,
              dangerouslyUseHTMLString: true,
              type: 'error',
              duration: 5000
            });
          });
      })
      .catch(() => { });
  }

  generaleValid?: boolean = null;
  programmaValid?: boolean = null;
  luogoValid?: boolean = null;
  referenteValid?: boolean = null;
  moduliValid?: boolean = null;
  sessioniValid?: boolean = null;
  relatoriValid?: boolean = null;
  rilevatoriValid?: boolean = null;

  async validateAllTabs(): Promise<boolean> {
    this.programmaValid = !!this.corso.programma && this.corso.programma.replace(/ /gi, '') !== "";
    this.luogoValid = await (this.$refs.luogo as any).validate();
    this.referenteValid = (this.$refs.referente as any).validate();
    this.moduliValid = (this.$refs.moduli as any).validate();
    this.sessioniValid = (this.$refs.sessioni as any).validate();
    this.relatoriValid = (this.$refs.relatori as any).validate();
    this.rilevatoriValid = (this.$refs.rilevatori as any).validate();
    return [
      this.programmaValid,
      this.luogoValid,
      this.referenteValid,
      this.moduliValid,
      this.sessioniValid,
      this.relatoriValid,
      this.rilevatoriValid
    ].reduce((a, b) => a && b, true);
  }

  async validateForms(): Promise<boolean> {
    const def = new Deferred<boolean>();
    (this.$refs.dataCorsoForm as ElForm).validate(async (v) => {
      if (this.stato === 'inCompilazione') {
        this.generaleValid = v;
        def.resolve(v)
      } else {
        this.generaleValid = v;
        def.resolve(v && await this.validateAllTabs());
      }
    });
    return def.promise;
  }

  compareEqualString(string?: string): string {
    return (string ?? '').toLowerCase().replace(/ /gi, '')
  }

  get modLuogo(): boolean {
    if (this.corso.virtuale !== this.corsoRef.virtuale)
      return true;

    if (this.corso.virtuale) {
      return this.compareEqualString(this.corso.uri) != this.compareEqualString(this.corsoRef.uri)
    } else {
      return this.compareEqualString(this.corso.citta) != this.compareEqualString(this.corsoRef.citta) ||
        this.compareEqualString(this.corso.indirizzo) != this.compareEqualString(this.corsoRef.indirizzo) ||
        this.compareEqualString(this.corso.cap) != this.compareEqualString(this.corsoRef.cap) ||
        this.compareEqualString(this.corso.nomeLuogo) != this.compareEqualString(this.corsoRef.nomeLuogo)
    }
  }

  get modDate(): boolean {
    return new Date(this.corso.data).getTime() != new Date(this.corsoRef.data).getTime();
  }

  saving = false;
  async save() {
    const valid = await this.validateForms();
    if (valid) {
      if (!this.corso.continuativo && !this.corso.pofId) {
        const pof = (await pofService.GetByDate(new Date(this.corso.data)));
        if (pof) {
          this.corso.pofId = pof.id;
          this.corso.periodoFormativoId = pof.periodoFormativoId;
        }
      }

      if (!this.corso.continuativo && !this.corso.pofId && this.stato === "proposto" && this.corso.stato === "inCompilazione") {
        this.$message({
          message: "Non puoi proporre il corso. Nessun `Piano Offerta Formativa` associabile alla data selezionata",
          type: "error",
          showClose: true,
          center: true
        });
        return;
      }

      if (this.corso.continuativo && (!this.corso.periodoFormativoId || this.corso.periodoFormativoId === '00000000-0000-0000-0000-000000000000') && this.stato === "proposto" && this.corso.stato === "inCompilazione") {
        this.$message({
          message: "Non puoi proporre il corso. Nessun `Periodo Formativo` associabile alla data selezionata",
          type: "error",
          showClose: true,
          center: true
        });
        return;
      }

      if (this.corso.continuativo && !this.corso.virtuale) {
        this.$alert(`Un corso On Demand non può essere "In presenza" ma va impostato come "Virtuale (URL)" nella scheda "Luogo"`, 'Attenzione!', {
          confirmButtonText: 'Ok',
          type: "warning",
          dangerouslyUseHTMLString: true
        });
        return;
      }

      if (!this.corso.continuativo && this.corso.virtuale && this.durataTotaleInMinutiModuli / 60 > this.webinarMaxOre) {
        this.$alert(`La <strong>durata totale dei moduli</strong> supera la <strong>durata massima di ${this.webinarMaxOre} ore</strong> per il corso Webinar`, 'Attenzione!', {
          confirmButtonText: 'Ok',
          type: "warning",
          dangerouslyUseHTMLString: true
        });
        return;
      }

      if (this.stato === 'rifiutato'){ //&& this.corso.stato !== this.stato) {
        this.$prompt(
          `Rifiutare il <i>corso proposto</i><br><strong>${this.corso.titolo}</strong>,<br>richiede l'inserimento di una motivazione.`,
          `Attenzione!`,
          {
            confirmButtonText: 'Ok',
            cancelButtonText: 'Annulla',
            type: 'warning',
            dangerouslyUseHTMLString: true,
            inputType: 'textarea',
            inputPlaceholder: 'Inserisci la motivazione del rifiuto',
            //inputPattern: /^\s*\S.{0,2000}$/,
            inputPattern: /(\s*\S.*)*[\r\n]*(\s*\S.*)/,
            inputErrorMessage: `E' obbligatorio inserire la motivazione per cui il corso viene rifiutato con un massimo di 2000 caratteri`
          }
        ).then((motivazione: any) => {
          this.saving = true;
          corsiService
            .ChangeStato(this.corso.id, this.stato, motivazione.value)
            .then(() => {
              this.$message({
                message: `Salvataggio del corso <strong><i>${this.corso.titolo}</i></strong> in stato ${this.$t(`corso.stati.${this.stato}`)} effettuato con successo`,
                dangerouslyUseHTMLString: true,
                type: 'success',
                duration: 5 * 1000
              });

              this.saving = false;
              this.$router.push('/corsi');
            })
            .catch(() => {
              this.$message({
                message: `Si è verificato un errore nel salvataggio in stato ${this.$t(`corso.stati.${this.stato}`)} del corso <strong><i>${this.corso.titolo}</i></strong>.`,
                showClose: true,
                dangerouslyUseHTMLString: true,
                type: 'error',
                duration: 5 * 1000
              });
              this.saving = false;
            });
        }).catch(() => { });
        return;
      }

      let testoConfirm = ""
      const notificaDaModificaLuogoData = !this.corso.continuativo
        // && this.quantitaIscritti > 0
        && (this.corsoRef.stato !== 'inCompilazione' && this.corsoRef.stato !== 'proposto')
        && (this.modLuogo || this.modDate);

      if (notificaDaModificaLuogoData) {
        const msg = `Variando Luogo e/o Data di questo corso i partecipanti (numero di iscritti: ${this.quantitaIscritti}) riceveranno un avviso contenente le nuove indicazioni.`
        testoConfirm += `${msg}<br>`;
      }

      if (this.stato === 'chiuso') {
        const msg = `La chiusura del corso scatena l'assegnazione di assenze ingiustificate e quindi l'inserimento in blacklist.`;
        testoConfirm += `${msg}<br>`
      }

      this.$confirm(`${testoConfirm}<strong>Confermi la modifica?</strong>`, 'Attenzione!', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annulla',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {

        this.saving = true;

        if (this.corso.virtuale) {
          this.corso.citta = null;
          this.corso.indirizzo = null;
          this.corso.cap = null;
          this.corso.nomeLuogo = null;
        } else {
          this.corso.uri = null;
        }

        corsiService
          .Set(this.corso)
          .then(async (corso: corso.item) => {
            debugger
            if (corso.stato !== this.stato) {
              await corsiService.ChangeStato(corso.id, this.stato);
            }

            this.$message({
              message: `Salvataggio del corso <strong><i>${this.corso.titolo}</i></strong> effettuato con successo`,
              dangerouslyUseHTMLString: true,
              type: 'success',
              duration: 5 * 1000
            });

            if (notificaDaModificaLuogoData) {
              giornalistiCorsoService.NotificaModLuogoDataReferente({
                userId: this.user.id,
                corsoId: this.corso.id,
                luogo: this.modLuogo,
                dateCorso: this.modDate,
                relatori: false
              });
            }

            this.saving = false;
            this.$router.push('/corsi');
          })
          .catch((e) => {
            console.error(e)
            const message = this.stato === "proposto" ? "</br>Per proporre un corso il pof associato deve corrispondere a quello corrente" : "";
            this.$message({
              message: `Si è verificato un errore nel salvataggio del corso <strong><i>${this.corso.titolo}</i></strong>.<br>Verificare di aver compilato correttamente tutti i campi e riprovare. ${message}`,
              showClose: true,
              dangerouslyUseHTMLString: true,
              type: 'error',
              duration: 5 * 1000
            });
            this.saving = false;
          });
      })
    }
  }
}