import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { argomentiService } from '../../service/argomentiService';

@Component({
  components: {},
  name: 'listaArgomenti'
})
export default class ListaArgomenti extends Vue {
  @Prop({ required: true })
  modulo: modulo.item;
  @Prop()
  argomentoParent: argomento.item;

  argomenti: argomento.itemRow[] = [];
  argomento: argomento.item = null;
  showAdd: boolean = false;
  loading = false;

  get rules() {
    return {
      nome: [{ required: true, message: 'Nome argomento richiesto', trigger: 'blur' }]
    }
  };

  async mounted() {
    let result: argomento.item[] = [];
    if (this.argomentoParent && this.argomentoParent.id) {
      result = await argomentiService.ListSubArgumenti(this.modulo, this.argomentoParent);
    } else {
      result = await argomentiService.List(this.modulo);
    }

    this.argomenti = result.map((m: argomento.itemRow) => {
      m.edit = false;
      return m;
    });
  }

  handleCreate() {
    this.argomento = {
      moduloId: this.modulo.id,
      argomentoParentId: this.argomentoParent?.id,
      nome: null
    } as argomento.item;
    this.showAdd = true;
  }

  handleEdit(row: argomento.itemRow) {
    this.argomento = Object.assign({}, row);
    row.edit = true;
  }

  handleCancel(row?: argomento.itemRow) {
    if (row) {
      Object.assign(row, this.argomento);
      this.$nextTick(() => {
        row.edit = false;
      });
    }
    this.argomento = null;
    this.showAdd = false;
  }

  saving: boolean = false;
  async handleSet(row?: argomento.itemRow) {
    (this.$refs.form as any).validate(async valid => {
      if (valid) {
        this.saving = true;
        const elem = this.showAdd ? this.argomento : row;
        await argomentiService
          .Set(this.modulo, elem)
          .then((argomento: argomento.item) => {
            const idx = this.argomenti.findIndex(x => x.id == argomento.id);

            const row: argomento.itemRow = {
              id: argomento.id,
              moduloId: argomento.moduloId,
              argomentoParentId: argomento.argomentoParentId,
              nome: argomento.nome,
              edit: false
            };

            if (idx !== -1) {
              this.argomenti[idx] = row;
              this.$message({
                message: `Argomento <strong>${argomento.nome}</strong> aggiornato con successo.`,
                duration: 5000,
                type: 'success',
                dangerouslyUseHTMLString: true
              });
            } else {
              this.argomenti.push(row);
              this.$message({
                message: `Argomento <strong>${argomento.nome}</strong> creato con successo.`,
                duration: 5000,
                type: 'success',
                dangerouslyUseHTMLString: true
              });
            }
            if (row) {
              this.$nextTick(() => (row.edit = false));
            }
            this.argomento = null;
            this.showAdd = false;
            this.saving = false;
          })
          .catch(() => {
            this.saving = false;
            this.$message({
              center: true,
              message: `Errore durante l'operazione di ${row ? 'aggiornamento' : 'inserimento'} dell'argomento <strong>${
                row?.nome
              }</strong>.`,
              duration: 5000,
              type: 'error',
              showClose: true,
              dangerouslyUseHTMLString: true
            });
          });
      }
    });
  }

  async handleRemove(row) {
    this.$msgbox({
      title: `Attenzione`,
      message: `L'argomento <strong>${row.nome}</strong> verrà cancellato definitivamente. Continuare?`,
      showCancelButton: true,
      confirmButtonText: `Si`,
      cancelButtonText: `Annulla`,
      dangerouslyUseHTMLString: true,
      type: 'warning'
    })
      .then(async () => {
        await argomentiService.Remove(this.modulo, row).then(() => {
          const idx = this.argomenti.findIndex(x => x.id === row.id);
          if (idx !== -1) this.argomenti.splice(idx, 1);
        });
      })
      .catch(e => console.info(e));
  }
}
