import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ModuleOptions } from 'vuex';
import { moduliService } from '../../service/moduliService';
import dialogInsertModulo from '../dialogInsertModulo/dialogInsertModulo.vue';

@Component({
  components: {
    dialogInsertModulo
  }
})
export default class ListaModuli extends Vue {
  @Prop()
  corsoId: string;

  @Prop({ required: false, default: false })
  disabled: boolean;

  moduli: modulo.item[] = [];

  loading: boolean = false;

  modulo: modulo.item = {} as modulo.item;
  dialogModuloVisible = false;

  query = {
    search: null,
    page: 1,
    take: null
  };

  async mounted() {
    this.loading = true;
    await this.fetchData()
    this.loading = false;
  }

  async fetchData() {
    this.moduli = await moduliService.List(this.corsoId);
  }

  get moduliFiltered() {
    if (this.query.search)
      return this.moduli.filter(x => x.nome.toLowerCase().includes(this.query.search.toLowerCase()));
    return this.moduli;
  }

  async handleEdit(row) {
    this.modulo = row;
    this.dialogModuloVisible = true;
  }

  handleCreate() {
    this.modulo = {} as modulo.item;
    this.dialogModuloVisible = true;
  }

  async handleRemove(row) {
    this.$msgbox({
      title: `Attenzione`,
      message: `Il modulo <strong>${row.nome}</strong> verrà cancellato definitivamente. Continuare?`,
      showCancelButton: true,
      confirmButtonText: `Si`,
      cancelButtonText: `Annulla`,
      dangerouslyUseHTMLString: true,
      type: 'warning'
    }).then(async () => {
      try {
        await moduliService.Remove(row.corsoId, row.id);
        const idx = this.moduli.map(m => m.id).indexOf(row.id);
        if (idx >= 0) {
          this.moduli.splice(idx, 1)
        }
        this.$emit('change', this.moduli);
      } catch (err) {
        return;
      }
    })
      .catch(e => console.info(e));
  }

  onChange(modulo: modulo.item) {
    const idx = this.moduli.map(m => m.id).indexOf(modulo.id);
    if (idx >= 0) {
      this.moduli.splice(idx, 1, modulo)
    } else {
      this.moduli.push(modulo);
    }
    this.dialogModuloVisible = false;
    this.$emit('change', this.moduli);
  }

  onClose(value: boolean) {
    this.dialogModuloVisible = false;
  }

  openDialog() {
    this.modulo = {
      minuti: 0,
      ore: 0,
      corsoId: this.corsoId,
      durataTimeSpan: null,
      nome: null,
      argomenti: []
    } as modulo.item;

    this.dialogModuloVisible = true;
  }


  validate() {
    return this.moduli.length > 0
  }
}
