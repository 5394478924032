import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { categorieService } from "../services/categorieService";


@Component({})
export default class categoriaAutocomplete extends Vue {
    @Prop()
    value?: string;

    @Prop({ default: false })
    clearable?: boolean
    @Prop({ default: false })
    defaultFirstOption: boolean
    @Prop({ default: 'Ricerca la categoria' })
    placeholder?: string
    @Prop({})
    size?: 'medium' | 'mini' | 'small'
    @Prop({})
    className?: string
    @Prop({default: false})
    disabled: boolean

    loading: boolean = false;
    options: { value: string, label: string }[] = [];

    public get selected(): string {
        return this.value;
    }
    public set selected(value: string) {
        this.$emit('input', value);
        this.$emit('change', value);
    }

    created() {
        this.initDefaultSelect();
    }

    @Watch("value")
    async valueChange(n, o) {
        if (n !== o) {
            await this.initDefaultSelect()
        }
    }

    async initDefaultSelect() {
        if (this.value) {
            const s = await categorieService.Detail(this.value);
            if (!s) {
                this.selected = null;
            } else if (this.options.findIndex(f => f.value === s.id) < 0) {
                this.options.push({ label: s.nome, value: s.id })
            }
        } else {
            if (this.selected) this.selected = null;
        }
    }

    async remoteMethod(query) {
        if (query !== '') {
            this.loading = true;
            const users = await categorieService.List(query);
            this.options = users.map(m => { return { label: m.nome, value: m.id } });
            this.loading = false;
        } else {
            this.options = [];
        }
    }
}