import { render, staticRenderFns } from "./manage-sessione.vue?vue&type=template&id=450fca7e&scoped=true"
import script from "./manage-sessione.ts?vue&type=script&lang=ts&external"
export * from "./manage-sessione.ts?vue&type=script&lang=ts&external"
import style0 from "./manage-sessione.vue?vue&type=style&index=0&id=450fca7e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "450fca7e",
  null
  
)

export default component.exports