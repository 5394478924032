import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class ArgomentiService extends baseRestService {
    constructor() {
        super();
        
        this.baseUrl = () => settings.baseUrl;
    }

    async List(modulo: modulo.item): Promise<argomento.item[]> {
        return await this.Get(`/api/corsi/${modulo.corsoId}/moduli/${modulo.id}/argomenti`);
    }

    async ListSubArgumenti(modulo: modulo.item, argomento: argomento.item): Promise<argomento.item[]> {
        return await this.Get(`/api/corsi/${modulo.corsoId}/moduli/${modulo.id}/argomenti/${argomento.id}/sub-argomenti`);
    }

    async Set(modulo: modulo.item, argomento: argomento.item): Promise<argomento.item> {
        return await this.Post(`/api/corsi/${modulo.corsoId}/moduli/${modulo.id}/argomenti`, argomento);
    }

    async Remove(modulo:modulo.item, argomento: argomento.item) {
        return await this.Delete(`/api/corsi/${modulo.corsoId}/moduli/${modulo.id}/argomenti/${argomento.id}`);
    }
}

export const argomentiService = new ArgomentiService();