import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

export interface ICategorieQueryParams { filter: string, page: number, size: number }
class CategorieService extends baseRestService {
  constructor() {
    super();
    this.baseUrl = () => settings.baseUrl;
  }

  async List(params?: ICategorieQueryParams): Promise<categoria.item[]> {
    return await this.Get(`/api/corsi/categorie`, params);
  }

  async Detail(id: string): Promise<categoria.item> {
    return await this.Get(`/api/corsi/categorie/${id}`);
  }
  
  async Set(categoria: categoria.item): Promise<categoria.item> {
    return await this.Post('/api/corsi/categorie', categoria)
  }
  
}

export const categorieService = new CategorieService();